import * as React from "react"

export default (props) => (
  <svg
    style={{width: '1.71rem', height: '1.71rem'}}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"8E02CE16-CB9C-4EE1-A326-4A30CAE9926C"}</title>
    <g transform="matrix(1 0 0 -1 .5 23)" fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path d="m14 16.455-.545.545L8 11.545 8.545 11z"/>
        <path d="M14 6.545 13.455 6 8 11.455l.545.545z"/>
      </g>
      <circle stroke="currentColor" cx={11.5} cy={11.5} r={11}/>
    </g>

  </svg>
);
