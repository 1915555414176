import * as React from "react"

export default (props) => (
  <svg
    width={171} height={22}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"logo_black"}</title>
    <g fillRule="nonzero" fill="none">
      <path
        d="M68.571.017 112.527.01c.38.041.76.083 1.116.206.76.265 1.413.904 1.746 1.68.332.777.345 1.69.012 2.423-.156.343-.388.647-.62.95l-15.86 16.644-.072.004c-.177.007-.354.002-.53 0-.228-.003-.456 0-.684 0h-2.627l-.956.001h-1.826c-.268.001-.537.002-.802.001-.225 0-.448-.002-.683 0-.182.003-.372.008-.53 0-.102-.004-.191-.015-.343 0-.054.007-.116.016-.122 0-.006-.014.041-.052.081-.086.114-.1.168-.172.233-.25.104-.122.24-.26.369-.393.17-.178.327-.35.488-.523.194-.21.394-.422.592-.634l.68-.726.75-.805.808-.864.848-.907c.29-.31.581-.62.871-.932l.88-.94c.194-.209.388-.416.583-.623l7.83-8.218a1.66 1.66 0 0 0 .207-.317 1.022 1.022 0 0 0-.004-.808 1.022 1.022 0 0 0-.582-.56 1.698 1.698 0 0 0-.372-.068l-10.127.002-16.809 17.64h-8.139l16.808-17.64c-3.913.002-7.825.003-11.738.003L57.192 21.913h-9.484L68.57.018M49.283 5.185c-.078.172-.194.323-.31.475l-11.09 11.638c-.167.161-.334.323-.513.47-.36.296-.768.535-1.2.72a4.943 4.943 0 0 1-1.348.373c-.23.028-.462.037-.694.047l-21.943.004L27.314 3.034c6.844 0 13.688-.002 20.532-.004.19.021.38.042.558.104.38.132.706.452.873.84.166.388.172.845.006 1.211m9.975-3.279c-.332-.776-.985-1.415-1.745-1.68-.356-.124-.736-.165-1.116-.206L20.865.027 0 21.924l37.467-.008c.556-.023 1.112-.045 1.664-.112 1.11-.135 2.202-.45 3.237-.894a11.907 11.907 0 0 0 2.881-1.728c.429-.353.83-.74 1.23-1.128L58.65 5.28c.232-.304.464-.608.62-.95.334-.734.32-1.647-.013-2.424M119.794.007l-2.867 3.011 16.675-.003L115.605 21.9l9.021-.002 17.998-18.886c4.795-.001 5.042.01 8.916.012L154.248 0l-34.454.007"
        fill="currentColor"
      />
      <path
        fill="#E05929"
        d="M151.189 12.881h2.738V5.038h-2.738zM167.71 3.031h-13.111L157.258.01h13.593v6.47c0 1.002-.143 1.898-.427 2.688a5.387 5.387 0 0 1-1.255 2.015 5.449 5.449 0 0 1-2.023 1.263c-.797.29-1.707.435-2.731.435h-9.816V4.995h3.141v4.83h6.607c2.241 0 3.363-1.137 3.363-3.414v-3.38ZM151.21 3.025h2.114c.956-1.003 1.762-2.022 2.718-3.025h-2.122l-2.71 3.025Z"
      />
    </g>
  </svg>
);
