import * as React from "react"
export default (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={15}
    height={15}
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.281 13.129c.274.273.274.684 0 .93a.544.544 0 0 1-.437.191.659.659 0 0 1-.492-.191l-3.665-3.664a5.59 5.59 0 0 1-3.527 1.23C3.043 11.625.5 9.082.5 5.937.5 2.82 3.016.25 6.16.25c3.117 0 5.688 2.57 5.688 5.688 0 1.34-.438 2.57-1.23 3.527l3.663 3.664ZM1.812 5.938a4.353 4.353 0 0 0 4.375 4.375 4.37 4.37 0 0 0 4.375-4.376 4.388 4.388 0 0 0-4.374-4.375 4.37 4.37 0 0 0-4.375 4.375Z"
    />
  </svg>
);