import React from 'react';
import styles from './TextInput.module.css';

export default function TextInput({
  value,
  onChange,
  placeholder,
  ...props
}) {

  return (
    <input
      className={styles.textInput}
      type="text"
      onChange={onChange}
      value={value}
      placeholder={placeholder}
      {...props}
    />
  );
}
