import useAppStore from "../hooks/useAppStore";
import ColorSelector from "../molecules/ColorSelector";
import SizeSelector from "../molecules/SizeSelector";
import FlagSelector from "../molecules/FlagSelector";
import flagsGroup from "../configurations/flagsGroup";
import TextSelector from "../molecules/TextSelector";
import { useTranslation } from "react-i18next";
import QuantitySelector from "../molecules/QuantitySelector";
import Stack from "../atoms/Stack";
import { useMemo } from "react";

export const DEFAULT_DETAIL = {
  flag: null,
  size: null,
  text: ''
};

export default function VariantSelector({
  ...props
}) {
  
  const { t, i18n } = useTranslation();
  
  const {
    modelConfiguration, currentGroup, currentMaterials,
    setCurrentMaterials, applyingMaterials, details,
    textColor, setTextColor, addDetail, removeDetail,
    updateDetail, flagOutlineColor,setFlagOutlineColor
  } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    currentGroup: state.currentGroup,
    setCurrentMaterials: state.setCurrentMaterials,
    currentMaterials: state.currentMaterials,
    applyingMaterials: state.applyingMaterials,
    details: state.details,
    textColor: state.textColor,
    setTextColor: state.setTextColor,
    addDetail: state.addDetail,
    updateDetail: state.updateDetail,
    removeDetail: state.removeDetail,
    flagOutlineColor: state.flagOutlineColor,
    setFlagOutlineColor: state.setFlagOutlineColor
  }));
  
  const flag = useMemo(function() {
    return details.length === 0
      ? null
      : details[0].flag
  }, [details]);
  
  if(currentGroup === null) return null;
  
  const detail = details[0] || {...DEFAULT_DETAIL};
  
  const material = modelConfiguration.configurableGroups.find( group => group.name === currentGroup.name);
  
  const selection = (selectedMaterial) => {
    return currentMaterials
      .filter( material => material.group.name !== selectedMaterial.group.name)
      .concat(selectedMaterial);
  };

  const getSelected = () => {
    const currentMaterial = currentMaterials.find( material => material.group.name === currentGroup.name);
    const configuration =  currentMaterial !== undefined ? currentMaterial.configuration : null;

    return configuration && material.configurations.find( item => item.name === configuration.name);
  }
  
  const updateDetails =  function (item) {
    
    if (details.length === 0) {
      addDetail({
        ...detail,
        ...item
      });
    } else {
      updateDetail({
        ...detail,
        ...item
      }, 0);
    }
    
    if (item.text && item.text === '') setTextColor(null);
  }
  
  switch (currentGroup.selectorType) {

    case 'color':
      return (
        <ColorSelector
          items={material.configurations}
          onSelect={item => {
            setCurrentMaterials(
              selection({
                group: currentGroup,
                configuration: item,
              })
            )}}
          selected={getSelected()}
          disabled={applyingMaterials}
        />
      );

    case 'size':
      return (
        <SizeSelector
          items={material.configurations}
          onSelect={item => updateDetails({ size: item.name })}
          selected={details.length > 0 ? details[0].size : null}
          disabled={applyingMaterials}
        />
      );
      
    case 'flag':
      return (
        <FlagSelector
          items={material.configurations}
          selected={flagsGroup.configurations.find(item => details.length > 0 && details[0].flag === item.name)}
          onSelect={item => {
            if(
              flag === null ||
              (flag !== null && item.name !== flag)
            ) {
              updateDetails({ flag: item.name });
              return;
            }
            updateDetails({ flag: null });
            setFlagOutlineColor(null);
          }}
          outlineColor={flagOutlineColor}
          onOutlineSelect={item =>
              setFlagOutlineColor({
                name: item.name,
                code: item.code,
              })
          }
          disabled={applyingMaterials}
          outlineDisabled={flag === null}
        />
      );
    
    case 'text':
      const value = details.length > 0 ? details[0].text : '';
      return (
        <Stack halign="center">
          <TextSelector
            value={value}
            placeholder={t('text.namePlaceholder')}
            onChange={ text => {
              updateDetails({ text });
              if(text === '') setTextColor(null);
            }}
          />
          <ColorSelector
            items={material.configurations}
            onSelect={item => {
              if(
                textColor === null ||
                (textColor !== null && textColor.code !== item.code)
              ) {
                setTextColor({
                  name: item.name,
                  code: item.code,
                });
                return;
              }
              
              setTextColor(null);
              updateDetails({ text: '' })
            }}
            selected={textColor}
            disabled={applyingMaterials || value === ''}
          />
        </Stack>
      );
    
    case 'quantity':
      const qta = details.length === 0 ? 1 : details.length;
      return (
        <QuantitySelector
          value={qta}
          onDec={ () => removeDetail(qta - 1) }
          onInc={ () => addDetail({...DEFAULT_DETAIL}) }
        />
      );
      
    default:
      console.error(`unknown group ${currentGroup}`);
      return null;
  }

}
