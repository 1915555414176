export default {
  id: 'nome',
  name: "NOME",
  label: "Nome",
  selectorType: "text",
  camera:  {
    "position": [0.20802095532417297,0.10165330767631531,0.2336360365152359],
    "target": [-0.07206711173057556,0.03663841634988785,-0.033031120896339417],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  configurations: [{
    name: "Bianco",
    color: "#ffffff",
    code: 'TXT01',
  },{
    name: "Nero",
    color: "#000000",
    code: 'TXT02',
  }]
}