{
  "button": {
    "back": "back",
    "cancel": "cancel",
    "next": "next",
    "confirm": "confirm",
    "virtualTryOn": "Try it next to you",
    "changeTheme": "Change theme",
    "reset": "reset",
    "purchase": "purchase",
    "backToConfigurator": "Back to configurator",
    "configurator": "configurator"
  },
  "text": {
    "notice": "warning",
    "reset": "CARRYING OUT THE RESET WILL DELETE THE CONFIGURATIONS SAVED UP TO HERE",
    "countrySearchPlaceholder": "Search country",
    "charactersLimit": "MAX 7 CHARACTERS",
    "namePlaceholder": "Add custom text",
    "personalizationWarning": "Personalization will apply to right shoe only",
    "quantityPersonalizationLabel": "HOW MANY PAIRS WOULD YOU LIKE TO CUSTOMIZE?",
    "pairs": "pairs"
  },
  "label": {
    "bianco": "white",
    "nero": "black",
    "giallo": "yellow",
    "grigio": "grey",
    "coral": "coral",
    "rosso": "red",
    "blu": "blue",
    "arancio": "orange",
    "orange": "orange",
    "antracite": "anthracite",
    "boanero": "gunmetal",
    "rame": "copper",
    "argento": "silver",
    "celestino": "ocean",
    "azzurro": "sky blue",
    "verde": "green",
    "mattone": "brick",
    "lilla": "soft violet",
    "rosa": "pink fluo",
    "rosa fluo": "pink fluo",
    "rosa giro": "pink giro",
    "viola": "violet"
  },
  "material": {
    "linguetta": "Tongue",
    "knit": "Knit Upper",
    "skin": "skin",
    "retro banda": "Heel Skin",
    "retro logo": "Heel Logo",
    "passacavi": "Webbing",
    "boa": "boa",
    "lacci": "laces",
    "taglia": "size",
    "bandiera": "flag",
    "nome": "name",
    "quantità": "quantity"
  }
}
