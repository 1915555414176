.flag {
  width: 2rem;
  height: 1.5rem;
  background-size: contain;
}

.flagSmall {
  width: 1.25rem;
  height: .93rem;
  background-size: contain;
}

.are { background-image: url("../../assets/ARE.png"); }
.arg { background-image: url("../../assets/ARG.png"); }
.aus { background-image: url("../../assets/AUS.png"); }
.aut { background-image: url("../../assets/AUT.png"); }
.bel { background-image: url("../../assets/BEL.png"); }
.bhr { background-image: url("../../assets/BHR.png"); }
.bra { background-image: url("../../assets/BRA.png"); }
.can { background-image: url("../../assets/CAN.png"); }
.che { background-image: url("../../assets/CHE.png"); }
.deu { background-image: url("../../assets/DEU.png"); }
.dnk { background-image: url("../../assets/DNK.png"); }
.eng { background-image: url("../../assets/ENG.png"); }
.fin { background-image: url("../../assets/FIN.png"); }
.fra { background-image: url("../../assets/FRA.png"); }
.hkg { background-image: url("../../assets/HKG.png"); }
.hrv { background-image: url("../../assets/HRV.png"); }
.irl { background-image: url("../../assets/IRL.png"); }
.isl { background-image: url("../../assets/ISL.png"); }
.ita { background-image: url("../../assets/ITA.png"); }
.jpn { background-image: url("../../assets/JPN.png"); }
.kor { background-image: url("../../assets/KOR.png"); }
.lux { background-image: url("../../assets/LUX.png"); }
.mlt { background-image: url("../../assets/MLT.png"); }
.nld { background-image: url("../../assets/NLD.png"); }
.nor { background-image: url("../../assets/NOR.png"); }
.nzl { background-image: url("../../assets/NZL.png"); }
.omn { background-image: url("../../assets/OMN.png"); }
.pol { background-image: url("../../assets/POL.png"); }
.prt { background-image: url("../../assets/PRT.png"); }
.sau { background-image: url("../../assets/SAU.png"); }
.spa { background-image: url("../../assets/SPA.png"); }
.svn { background-image: url("../../assets/SVN.png"); }
.swe { background-image: url("../../assets/SWE.png"); }
.tur { background-image: url("../../assets/TUR.png"); }
.twn { background-image: url("../../assets/TWN.png"); }
.usa { background-image: url("../../assets/USA.png"); }
.zaf { background-image: url("../../assets/ZAF.png"); }
.gbs { background-image: url("../../assets/GB-SCT.png"); }

.container {
  overflow-x: hidden;
  overflow-y: hidden;
  max-width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in;
  text-transform: uppercase;
}

.container:hover {
  overflow-x: auto;
}


.iconWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
  display: flex;
  align-items: center;
}

.xIconContainer {
  background-color: #dedede;
  color: #000;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(.5);
}

.xIconContainer:hover {
  cursor: pointer;
}

.fadeout {
  opacity: 0;
}

.container span {
  text-transform: uppercase;
}

.flagContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: .2rem;
  border: 1px solid transparent;
  border-radius: .375rem;
  box-sizing: border-box;
  transition: all 100ms ease-in;
  text-transform: uppercase;
}

.flagContainer:hover {
  border: 1px solid #ff7500;
}

.selected {
  composes: flagContainer;
  border: 1px solid #ff7500;
}

.container button {
  color: var(--main-container-foreground-color);
}

.outline {
  border-radius: 4px;
  border: 2px solid transparent;
  box-sizing: border-box;
  background-color: transparent;
}

.outlineContainer {
  border-radius: 4px;
  padding: .2rem;
}

.outlineContainer:hover {
  cursor: pointer;
  background-color: #ff7500;
}

.outlineSelected {
  background-color: #ff7500;
}

.outlineDisabled {
  pointer-events: none;
  opacity: .2;
}

.small {
  composes: outline;
  width: 1.8rem;
  height: 1.35rem;
}

.medium {
  composes: outline;
  width: 2rem;
  height: 1.5rem;
}