@font-face {
  font-family: 'Gotham-Black';
  src: url('./fonts/Gotham-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham-Bold';
  src: url('./fonts/Gotham-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'Gotham-Book';
  src: url('./fonts/Gotham-Book.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Gotham-Light';
  src: url('./fonts/Gotham-Light.woff2') format('woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Gotham-Medium';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Gotham-Medium.otf') format('opentype');
}

html, body {
  font-family: "Gotham-Book", sans-serif;
}

.light {
  font-family: "Gotham-Light", sans-serif;
}

.book {
  font-family: "Gotham-Book", sans-serif;
}

.medium {
  font-family: "Gotham-Medium", sans-serif;
}

.bold {
  font-family: "Gotham-Bold", sans-serif;
}

.black {
  font-family: "Gotham-Black", sans-serif;
}

.size-small {
  font-size: .625rem;
}

.size-normal {
  font-size: .75rem;
}

.size-medium {
  font-size: .875rem;
}

.size-large {
  font-size: 1rem;
}

.spacing-normal {
  letter-spacing: .0625rem;
}

.spacing-medium {
  letter-spacing: .203rem;
}

.spacing-large {
  letter-spacing: 2rem;
}

.spacing-xlarge {
  letter-spacing: 2.375rem;
}

.gap-none {
  gap: 0;
}

.gap-small {
  gap: .25rem;
}

.gap-normal {
  gap: .5rem;
}

.gap-medium {
  gap: 1rem;
}

.gap-large {
  gap: 1.5rem;
}

.gap-xlarge {
  gap: 2.5rem;
}


.w100 {
  width: 100%;
}


.main {
  composes: w100;
  color: var(--main-container-foreground-color);
  background: var(--main-container-background-color);
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 2rem;
  height: 2.375rem;
  composes: medium size-small spacing-normal;
  padding: .875rem 2rem;
  border: 1px solid #f1efef;
  border-radius: 2rem;
  transition: all 150ms ease-in-out;
  outline: none;
  text-transform: uppercase;
}

.button:enabled:hover {
  cursor: pointer;
}

.button:disabled,
.button[disabled] {
  opacity: .5;
}

.button:disabled:hover,
.button[disabled][hover] {
  cursor: auto;
}

.button:enabled span:hover {
  opacity: .2;
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .main {
    height: 100%;
  }

  .size-large {
    font-size: 1.375rem;
  }
}
