import React, {useEffect, useLayoutEffect} from 'react';
import "./reset.css";
import * as styles from "./style.module.css";
import themes from "./themes";
import useAppStore from "./hooks/useAppStore";
import EmersyaViewerProvider from "./EmersyaViewerProvider";
import i18n from "./i18n";

const applyTheme = (theme) => {
  const properties = Object.keys(themes[theme]);

  properties.forEach(property => {
    const value = themes[theme][property];
    document.documentElement.style.setProperty(property, value)
  });
};

export default function App({
  children
 }) {

  const {theme, lang} = useAppStore( state => ({
    theme: state.theme,
    lang: state.lang,
  }));

  useLayoutEffect(function () {
    applyTheme(theme)
  }, [theme]);

  useEffect(function(){
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <EmersyaViewerProvider>
      <div className={styles.main}>
        {children}
      </div>
    </EmersyaViewerProvider>
  );
}
