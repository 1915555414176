export default {
  masterSkuKr0: {
    id: 'quantita',
    name: "QUANTITÀ",
    label: "Quantità",
    selectorType: "quantity",
    camera:  {
      "position": [0.20802095532417297,0.10165330767631531,0.2336360365152359],
      "target": [-0.07206711173057556,0.03663841634988785,-0.033031120896339417],
      "up": [0, 1, 0],
      "fov": 30,
      "transitionTime": 1000
    },
  },
  masterSkuPogis: {
    id: 'quantita',
    name: "QUANTITÀ",
    label: "Quantità",
    selectorType: "quantity",
    camera:  {
      "position": [-10.228785514831543,7.752980709075928,5.360328674316406],
      "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
      "up": [0, 1, 0],
      "fov": 25,
      "transitionTime": 1000
    },
  }
}