import Label from "../atoms/Label";
import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import { useTranslation } from "react-i18next";
import * as styles from './QuantitySelector.module.css';
import React from "react";

export default function QuantitySelector({
  value= 1,
  onInc,
  onDec,
  ...props
}) {
  
  const { t, i18n } = useTranslation();
  
  return (
    <Stack halign="center" gap="medium">
      <Label
        spacing="medium"
        size="medium"
        weight="normal"
      >
        {t('text.quantityPersonalizationLabel')}
      </Label>
      <Inline gap="large">
        <button
          disabled={value === 1}
          onClick={onDec}
          className={styles.button}
        >
          <Label weight="normal" size="medium">-</Label>
        </button>
        <div className={styles.quantity}>
          <Label weight="weak" size="medium">{value}</Label>
        </div>
        <button
          onClick={onInc}
          className={styles.button}
        >
          <Label weight="normal" size="medium">+</Label>
        </button>
      </Inline>
    </Stack>
  );
}