import Progress from "../atoms/Progress";
import Logo from "../icons/Logo";
import Stack from "../atoms/Stack";
import * as styles from './Loading.module.css';

export default function Loading({
  progress = 0,
}) {

  return (
    <Stack halign={"center"} valign={"center"} gap="xlarge">
      <div style={{ width: '12rem'}}>
        <Logo className={styles.loading} />
      </div>
      <Progress loading={true} progress={progress} />
    </Stack>
  );
}
