import * as React from "react"

export default (props) => (
  <svg
    width={12}
    height={12}
    viewBox="0 0 12 12"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M6 0C9.30469 0 12 2.69531 12 6C12 9.32812 9.30469 12 6 12C2.67188 12 0 9.32812 0 6C0 2.69531 2.67188 0 6 0ZM6 10.875C8.67188 10.875 10.875 8.69531 10.875 6C10.875 3.32812 8.67188 1.125 6 1.125C3.30469 1.125 1.125 3.32812 1.125 6C1.125 8.69531 3.30469 10.875 6 10.875ZM6 7.125C5.67188 7.125 5.4375 6.89062 5.4375 6.5625V3.5625C5.4375 3.25781 5.67188 3 6 3C6.30469 3 6.5625 3.25781 6.5625 3.5625V6.5625C6.5625 6.89062 6.30469 7.125 6 7.125ZM6 7.92188C6.39844 7.92188 6.72656 8.25 6.72656 8.64844C6.72656 9.04688 6.39844 9.375 6 9.375C5.57812 9.375 5.25 9.04688 5.25 8.64844C5.25 8.25 5.57812 7.92188 6 7.92188Z" fill="#FF7500"/>
  </svg>
);