import ConfigurationItem from "./ConfigurationItem";
import Stack from "../atoms/Stack";
import useAppStore from "../hooks/useAppStore";
import {itemStatus} from "./ConfigurationItem";
import {useTranslation} from "react-i18next";
import { useCallback, useMemo } from "react";
import modes from "../configurations/modes";


function getMaterialByGroupName(materials, groupName) {
  return materials.find( material => material.group.name === groupName);
}

export default function MaterialsMenu({
  items,
  onSelect,
  ...props
}) {

  const {
    currentGroup, currentMaterials, modelConfiguration,
    details, textColor, mode, b2c
  } = useAppStore( state => ( {
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    modelConfiguration: state.modelConfiguration,
    details: state.details,
    textColor: state.textColor,
    mode: state.mode,
    b2c: state.b2c,
  }) );
  
  const flagSelected = useMemo(function(){
    return details.length > 0 && details[0].flag !== null;
  }, [details]);
  
  const nameSelected = useMemo(function(){
    return textColor !== null && details.length > 0 && details[0].text !== '';
  }, [textColor, details]);
  
  const sizeSelected = useMemo(function(){
    return details.length > 0 && details[0].size !== null;
  }, [details]);
  
  
  const lacesSelected = getMaterialByGroupName(currentMaterials, 'LACCI') !== undefined;
  
  // flag and text are not required
  const getDisabled = useCallback(function (index, name) {
    
    if (index === 0) return false;
    
    if(mode === modes.B2C && name === 'BANDIERA' ) {
      return !sizeSelected;
    }
    
    if(name === 'NOME') {
      return mode === modes.B2C
        ? !sizeSelected
        : !lacesSelected
    }
    
    if(name === 'QUANTITÀ') {
      return !lacesSelected
    }
    
    return currentMaterials[index - 1] === undefined;
  }, [currentMaterials, flagSelected, nameSelected, sizeSelected, mode]);


  const getStatus = useCallback(function (item) {
    if(currentGroup === null) return itemStatus.todo;

    const currentGroupStatus =
      currentMaterials.find( material => material.group.id === item.id) !== undefined ||
      (item.name === 'BANDIERA' && details.length > 0 && details[0].flag !== null) ||
      (item.name === 'NOME' && details.length > 0 && details[0].text !== '' && textColor !== null) ||
      (item.name === 'TAGLIA' && details.length > 0 && details[0].size !== null)
      ? itemStatus.done
      : itemStatus.todo

    return currentGroupStatus;
    
  }, [currentGroup, currentMaterials]);
  
  return (
    <Stack gap='none' {...props}>
      {items.map( (item, idx) => (
        <ConfigurationItem
          key={idx}
          index={idx+1}
          item={item}
          active={currentGroup && currentGroup.id === item.id}
          status={getStatus(item)}
          disabled={getDisabled(idx, item.name)}
          onSelect={onSelect}
        />
      ))}
    </Stack>
  );
};
