import * as React from "react"

export default (props) => (
  <svg
    width={29}
    height={29}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path d="M21.213 7.778 7.778 21.213l-.707-.707L20.506 7.071z" />
      <path d="M20.506 21.213 7.071 7.778l.707-.707 13.435 13.435z" />
    </g>
  </svg>
);
