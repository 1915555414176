import {useEffect} from "react";
import useAppStore from "./useAppStore";
export default function useViewerOverlayStatusUpdated(viewer, loaded) {

  const toggleShowARModal = useAppStore( state => state.toggleShowARModal);

  useEffect(function() {
    if(viewer === null) return;
    if(loaded === false) return;

    function listener(data){
      toggleShowARModal();
    }

    viewer.addEventListener('viewerOverlayStatusUpdated', listener, false);

    return function() {
      viewer.removeEventListener('viewerOverlayStatusUpdated', listener, false);
    };

  },[viewer, loaded]);

}
