.main {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
}

.contents {
  height: 100%;
}

.header {
  composes: w100 from "../style.module.css";
  height: 3.57rem;
  position: relative;
  z-index: 6;
  background-color: var(--main-container-background-color);
  transition: all 200ms ease-in-out;
  opacity: 1;
}

.menuContainer {
  position: absolute;
  top: 3.57rem; /* header height */
  left: 100%;
  width: 100%;
  right: 0;
  bottom: 0;
  background-color: var(--menu-footer-background-color);
  transition: all 200ms ease-in-out;
  z-index: 4;
  opacity: 1;
}

.menuContainerOpened {
  left: 0;
  z-index: 4;
}

.variantSelector {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  height: 9em;
  padding: 0;
}

.footer {
  composes: w100 from "../style.module.css";
  transition: all 200ms ease-in-out;
  opacity: 1;
  /*position: absolute;
  bottom: 0;*/
}

.purchaseFooter {
  padding-bottom: 1rem;
  height: 12rem;
}

.productInfo {
  composes: w100 from "../style.module.css";
  position: absolute;
  top: 3.5rem;
  display: flex;
  justify-content: center;
  transition: all 200ms ease-in-out;
}

.personalizationWarning {
  composes: w100 from "../style.module.css";
  position: absolute;
  top: 5.5rem;
  display: flex;
  justify-content: center;
  transition: all 200ms ease-in-out;
}

button {
  cursor: pointer;
}

.loading {
  position: absolute;
  left: 100%;
  top: 100%;
}

.ready {
  position: static;
  transform: translate(0,0);
}

.hide {
  opacity: 0;
  z-index: -1;
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .viewerContainer {
    width: 100%;
    height: 100%;
  }

  .menuContainer {
    top: 0;
    bottom: 0;
    left: unset;
    right: 0;
    width: 20.06rem;
    transition: none;
    background-color: transparent;
  }

  .variantSelector {
    /* we remove materials menu width to enable click on the last menu item  */
    max-width: calc(100% - 23.185rem - 7.65rem - 3.5rem - 5rem);
    padding: 1rem 0 0 0;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .menuFooter {
    display: none;
  }

  .productInfo {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    left: 3.125rem;
  }

  .personalizationWarning {
    top: 6rem;
  }

  .buttonContainer:hover {
    cursor: pointer;
    transform: scale(1.1);;
  }

  .backLabel {
    display: block;
  }

  .resetLabel {
    display: block;
  }
}
