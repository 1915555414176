import {create, useStore} from "zustand";
import {useContext} from "react";
import StoreContext from "../contexts/StoreContext";

export default function useAppStore(selector) {

  const store = useContext(StoreContext);
  if (!store) throw new Error('Missing BearContext.Provider in the tree')

  return useStore(store, selector);

}
