import * as React from "react"

export default (props) => (
  <svg
    width={26}
    height={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"1A60D69A-96A9-4652-A302-F91E1A042B7C"}</title>
    <g fill="none" fillRule="evenodd">
      <circle stroke="#FFF" cx={13} cy={13} r={12.5}/>
      <path
        d="M13.025 0c2.765 0 5.4.854 7.6 2.438a.269.269 0 0 1 .184.136l.034.025c.487.367.953.77 1.392 1.208a12.94 12.94 0 0 1 3.174 5.146.277.277 0 0 1 .045.139c.392 1.247.596 2.562.596 3.908 0 1.348-.204 2.664-.598 3.914a.265.265 0 0 1-.07.218 12.94 12.94 0 0 1-3.147 5.06c-.44.439-.905.842-1.392 1.209l-.035.024a.269.269 0 0 1-.189.139A12.936 12.936 0 0 1 13.025 26c-3.48 0-6.75-1.352-9.21-3.807A12.903 12.903 0 0 1 0 13c0-3.473 1.355-6.737 3.815-9.193A12.953 12.953 0 0 1 13.025 0ZM8.484 19.733l-2.569 3.502a12.426 12.426 0 0 0 6.836 2.22v-4.323a8.088 8.088 0 0 1-4.267-1.4Zm4.813 1.4v4.321h.035a12.426 12.426 0 0 0 6.836-2.242l-2.553-3.48a8.089 8.089 0 0 1-4.318 1.402Zm-7.891-5.341-4.16 1.333a12.515 12.515 0 0 0 4.23 5.79l2.57-3.502a8.174 8.174 0 0 1-2.64-3.621Zm12.648 3.62 2.55 3.48a12.514 12.514 0 0 0 4.204-5.78l-4.115-1.32a8.175 8.175 0 0 1-2.64 3.62ZM13.06 5.406l-.035.003-.029-.003-.192.004c-3.232.103-5.961 2.236-6.951 5.162a.267.267 0 0 1-.062.2 7.573 7.573 0 0 0-.032 4.358l.056.184c.011.017.02.036.027.056l.01.058c1.01 2.987 3.833 5.148 7.152 5.167l.02-.002.027.002.244-.004c3.233-.103 5.962-2.236 6.952-5.163 0-.02.004-.039.01-.058a.269.269 0 0 1 .028-.057c.233-.73.36-1.507.36-2.312a7.57 7.57 0 0 0-.335-2.23.228.228 0 0 1-.052-.09.265.265 0 0 1-.013-.106c-1.01-2.999-3.85-5.165-7.185-5.17ZM.543 13c0 1.256.187 2.47.535 3.614l4.16-1.333A8.115 8.115 0 0 1 4.912 13c0-.775.11-1.525.312-2.235L1.065 9.43A12.399 12.399 0 0 0 .543 13Zm24.446-3.555-4.114 1.32c.203.71.312 1.46.312 2.235 0 .791-.113 1.556-.325 2.28l4.114 1.32c.345-1.14.53-2.349.53-3.6a12.4 12.4 0 0 0-.517-3.555ZM20.605 3.11l-2.551 3.478a8.174 8.174 0 0 1 2.656 3.665l4.114-1.319a12.513 12.513 0 0 0-4.22-5.824ZM1.23 8.919l4.159 1.333a8.174 8.174 0 0 1 2.655-3.665L5.476 3.085a12.514 12.514 0 0 0-4.245 5.834ZM12.75.545l.274-.003c-2.64 0-5.09.822-7.109 2.223l2.568 3.502a8.088 8.088 0 0 1 4.267-1.399V.545Zm.546 0v4.321a8.092 8.092 0 0 1 4.318 1.401l2.552-3.479a12.426 12.426 0 0 0-6.87-2.243Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
);
