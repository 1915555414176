.item {
  display: flex;
  align-items: center;
  height: 2.5625rem;
  width: 100%;
  padding-left: 1.625rem;
  padding-right: .8125rem;
  border-bottom: 1px solid var(--configuration-item-border-bottom-color);
  box-sizing: border-box;
  font-weight: bold;
  border-left: 2px solid transparent;
  transition: all 300ms ease-in;
}

.item:hover {
  cursor: pointer;
  color: var(--item-active-color);
  border-left: 2px solid transparent;
}

.active {
  composes: item;
  color: var(--item-active-color);
  border-left: 2px solid transparent;
}

.todo {
  composes: item;
  color: var(--item-todo-color);
  font-weight: bold;
}

.done {
  composes: item;
  color: var(--item-done-color);
}

.disabled {
  composes: item;
  transition: none;
  cursor: initial;
}

.disabled:hover {
  cursor: initial;
  color: var(--item-todo-color);
  border-left: 2px solid transparent;
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .item:hover{
    border-left: 2px solid var(--item-active-color);
  }

  .active {
    border-left: 2px solid var(--item-active-color);
  }

  .disabled:hover {
    cursor: initial;
    color: var(--item-todo-color);
    border-left: 2px solid transparent;
  }

}
