import React from 'react';
import * as styles from "./Circle.module.css";

export default function Circle({
  size = 'small',
  color,
  ...props
}) {

  return (
    <div
      className={styles[size]}
      style={{backgroundColor: color}}
      {...props}
    />
  );
}
