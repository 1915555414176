import {useEffect} from "react";
import useAppStore from "./useAppStore";

export default function useCamera(viewer, loaded){

  const {toggleApplyingMaterials, currentGroup, modelConfiguration} = useAppStore( state => ({
    toggleApplyingMaterials: state.toggleApplyingMaterials,
    currentGroup: state.currentGroup,
    modelConfiguration: state.modelConfiguration
  }));

  const defaultCamera = modelConfiguration.configurableGroups[0].camera;

  useEffect(function() {
    if(viewer === null) return;
    if(loaded === false) return;

    toggleApplyingMaterials();
    viewer.setCamera({
      camera : currentGroup.camera?? defaultCamera
    })
      .then( data => {
        viewer.setHighlight({
          meshes : [],
          configurableMaterials: [currentGroup.name],
          configurableMaterialGroups : []
        });
      })
      .error( err => console.error(err));
    toggleApplyingMaterials()
  },[currentGroup]);

}
