import * as React from "react"

export default (props) => (
  <svg
    width={26}
    height={26}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"3C139562-3555-44FD-87B1-33E01ED5513F"}</title>
    <g fill="none" fillRule="evenodd">
      <circle cx={13} cy={13} r={12.5} stroke="currentColor"/>
      <path fill="currentColor" d="M12.462 4h1v10h-1zM22.018 14.139v1h-10v-1z"/>
      <path fill="currentColor"
            d="m12.671 14.555.329.274-5.671 4.726L7 19.281z"/>
    </g>
  </svg>
);
