import TextInput from "../atoms/TextInput";
import Label from "../atoms/Label";
import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import InfoIcon from "../icons/InfoIcon";
import { useTranslation } from "react-i18next";
import { useState } from "react";

export default function TextSelector({
  value,
  placeholder,
  onChange,
  maxLength = 7,
  ...props
}) {
  
  {/*<Label weight="weak">{t('text.charactersLimit')}</Label>*/}
  
  const [chars, setChars] = useState(value.length);
  
  const { t, i18n } = useTranslation();
  
  return (
    <div style={{width: '14rem', position: "relative"}}>
      <div style={{right: 0, top: 0, bottom: 0, position: "absolute", display:'flex', alignItems: "center", justifyContent: "center"}}>
        <Label weight="weak" size="medium" color="#979797">{chars}/{maxLength}</Label>
      </div>
      <TextInput
        value={value}
        placeholder={placeholder}
        onChange={(event) => {
          setChars(event.target.value.length);
          onChange(event.target.value);
        }}
        maxLength={maxLength}
        {...props}
      />
    </div>
  );
}