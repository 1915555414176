import Inline from "../atoms/Inline";
import * as styles from "./FlagSelector.module.css";
import Stack from "../atoms/Stack";
import Label from "../atoms/Label";
import {useTranslation} from "react-i18next";
import TextInput from "../atoms/TextInput";
import SearchIcon from "../icons/SearchIcon";
import React, { useEffect, useMemo, useState } from "react";
import XIcon from "../icons/XIcon";
import useAppStore from "../hooks/useAppStore";

export default function FlagSelector({
  items,
  selected,
  disabled= false,
  outlineColor = null,
  onSelect,
  onOutlineSelect,
  outlineDisabled,
}) {
  
  const { textColor, flagOutlineColor, details } = useAppStore( (state) => ({
    textColor: state.textColor,
    flagOutlineColor: state.flagOutlineColor,
    details: state.details,
  }));
  
  const textSelected = useMemo(function(){
    return details.length > 0 && details[0].text !== '';
  }, [details]);
  
  const nameSelected = useMemo(function(){
    return textColor !== null && textSelected;
  }, [textColor, textSelected]);
  
  const { t } = useTranslation();
  
  const [searchVisible, setSearchVisible] = useState(true);
  const [filteredItems, setFilteredItems] = useState([...items]);
  const [searchValue, setSearchValue] = useState('');
  
  useEffect(function() {
    
    if(searchValue === '') {
      setSearchVisible(true);
      setFilteredItems([...items]);
      return;
    }
    
    setSearchVisible(false);
    setFilteredItems(
      items.filter( item =>
        item.description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      )
    );
    
  }, [searchValue]);
  
  const handleTextChange = (event) => {
    setSearchValue(event.target.value);
  }
  
  const handleXiconClick = () => {
    setSearchValue('');
  }
  
  const flagContainerClassName = (item) => `${styles.flagContainer} ${selected && item.name === selected.name ? styles.selected : null}`;
  
  return (
    <Stack style={{width: "100%"}} halign="center" gap="medium">
      <Inline gap="large" verticalAlign={"center"}>
        <div style={{position: "relative", width: '15rem'}}>
          <TextInput
            placeholder={t('text.countrySearchPlaceholder')}
            onChange={handleTextChange}
            value={searchValue}
          />
          <div className={styles.iconWrapper}>
            {searchVisible
              ? <SearchIcon />
              : (
                <div
                  className={styles.xIconContainer}
                  onClick={handleXiconClick}
                >
                  <XIcon />
                </div>
              )
            }
          </div>
        </div>
        <Stack halign="center" valign="center">
          <Label size="small" weight="medium">outline color</Label>
          <Inline verticalAlign="center">
            <div
              className={`${styles.outlineContainer} ${outlineDisabled ? styles.outlineDisabled : null} ${outlineColor && outlineColor.name === 'Nero' ? styles.outlineSelected : null}`}
              onClick={() => onOutlineSelect({
                name: 'Nero',
                code: 'TXT02',
              })}
            >
              <Outline
                key={"black"}
                color={"#000"}
                size="medium"
              />
            </div>
            <div
              className={`${styles.outlineContainer} ${outlineDisabled ? styles.outlineDisabled : null}  ${outlineColor && outlineColor.name === 'Bianco' ? styles.outlineSelected : null}`}
              onClick={() => onOutlineSelect({
                name: 'Bianco',
                code: 'TXT01',
              })}
            >
              <Outline
                key={"white"}
                color={"#fff"}
                size="medium"
              />
            </div>
          </Inline>
        </Stack>
      </Inline>
      
      <div className={styles.container}>
        <Inline gap="xlarge" verticalAlign="center">
          {filteredItems.map( item => (
            <button
              key={item.name}
              onClick={ () => onSelect(item) }
              disabled={disabled}
            >
              <Stack halign="center" valign="center">
                <div className={flagContainerClassName(item)}>
                  <div className={`${styles.flag} ${styles[item.name.toLowerCase()]}`} />
                </div>
                <Label size="small" weight="medium">{item.label}</Label>
              </Stack>
            </button>
          ))}
        </Inline>
      </div>
      
    </Stack>
  );

}

function Outline({
  size = 'small',
  color,
  ...props
}) {
  
  return (
    <div
      className={styles[size]}
      style={{borderColor: color}}
      {...props}
    />
  );
}
