import Inline from "../atoms/Inline";
import Circle from "../atoms/Circle";
import * as styles from "./ColorSelector.module.css";
import Stack from "../atoms/Stack";
import Label from "../atoms/Label";
import {useTranslation} from "react-i18next";

export default function ColorSelector({
  items,
  selected,
  disabled= false,
  onSelect,
}) {

  const { t } = useTranslation();
  const circleContainerClassName = (item) => `${styles.circleContainer} ${selected && item.name === selected.name ? styles.selected : null}`;

  return (
    <div className={styles.container}>
      <Inline gap="xlarge" verticalAlign="center">
        {items.map( item => (
          <button
            key={item.name}
            onClick={ () => onSelect(item) }
            disabled={disabled}
          >
            <Stack halign="center" valign="center">
              <div className={circleContainerClassName(item)}>
                <Circle key={item.name} color={item.color} size="medium" />
              </div>
              <Label size="small" weight="medium">{t(`label.${item.name.toLowerCase()}`)}</Label>
            </Stack>
          </button>
        ))}
      </Inline>
    </div>
  );

}
