import React, {useEffect, useLayoutEffect} from 'react';
import "./reset.css";
import * as styles from "./style.module.css";
import themes from "./themes";
import useAppStore from "./hooks/useAppStore";
import EmersyaViewerProvider from "./EmersyaViewerProvider";
import i18n from "./i18n";
import enKr0 from './locales/kr0/en.json';
import itKr0 from './locales/kr0/it.json';
import deKr0 from './locales/kr0/de.json';
import enPogis from './locales/pogis/en.json';
import itPogis from './locales/pogis/it.json';
import dePogis from './locales/pogis/de.json';

const TRANSLATIONS = {
  masterSkuKr0: {
    en: enKr0,
    it: itKr0,
    de: deKr0,
  },
  masterSkuPogis: {
    en: enPogis,
    it: itPogis,
    de: dePogis,
  }
};

const applyTheme = (theme) => {
  const properties = Object.keys(themes[theme]);

  properties.forEach(property => {
    const value = themes[theme][property];
    document.documentElement.style.setProperty(property, value)
  });
};

export default function App({
  children
 }) {

  const {theme, lang, modelId} = useAppStore( state => ({
    theme: state.theme,
    lang: state.lang,
    modelId: state.modelId
  }));

  useLayoutEffect(function () {
    applyTheme(theme)
  }, [theme]);
  
  useEffect(function(){
    i18n.addResourceBundle(lang, "translation", TRANSLATIONS[modelId][lang]);
    i18n.changeLanguage(lang);
  }, [lang]);
  
  return (
    <EmersyaViewerProvider>
      <div className={styles.main}>
        {children}
      </div>
    </EmersyaViewerProvider>
  );
}
