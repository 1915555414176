.textIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.375rem;
  composes: bold size-small spacing-normal from "../style.module.css";
  transition: all 150ms ease-in-out;
  outline: none;
  color: var(--textIcon-color);
  padding: 0;
  text-transform: uppercase;
}

.textIcon:hover {
  opacity: 1;
  text-decoration: underline;
}

