.backLabel {
  display: none;
  text-transform: uppercase;
}

.resetLabel {
  display: none;
  text-transform: uppercase;
}

.disabled {
  opacity: .5;
  cursor: none;
  pointer-events: none;
}

.progress {
  composes: w100 from "../style.module.css";
  padding: .3125rem 1rem 0 1rem;
}

.navigation {
  composes: w100 from "../style.module.css";
  padding: .875rem 1rem;
}

.buttonContainer {
  transition: all 100ms ease-in;
}

.buttonContainer:hover {
  text-decoration: underline;
}

.buttonContainer button {
  color: var(--main-container-foreground-color);
}

.button {
  transition: all 150ms ease-in-out;
}

.button:disabled,
.button[disabled] {
  opacity: .5;
  cursor: auto;
}


/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

  .button:enabled:hover {
    cursor: pointer;
    text-decoration: underline;
  }

  .button:disabled,
  .button[disabled] {
    opacity: .5;
  }

  .button:disabled:hover,
  .button[disabled][hover] {
    cursor: auto;
  }

  .backLabel {
    display: block;
  }

  .resetLabel {
    display: block;
  }

  .progress {
    width: 100%;
    padding: 0.1865rem 3.125rem 0 3.125rem;
  }

  .navigation {
    padding: .875rem 3.125rem;
    z-index: 9;
  }

}
