import Inline from "../atoms/Inline";
import Logo from "../icons/Logo";
import HamburgerIcon from "../icons/HamburgerIcon";
import * as styles from './Header.module.css';
import TextIconButton from "../molecules/TextIconButton";
import useAppStore from "../hooks/useAppStore";
import {useContext} from "react";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import XIcon from "../icons/XIcon";
import {useTranslation} from "react-i18next";

export default function Header({
  ...props
}) {

  const { t, i18n } = useTranslation();

  const {toggleTheme, toggleMenu, showRecap, toggleShowRecap, menuOpened} = useAppStore( (state) => ({
    toggleTheme: state.toggleTheme,
    toggleMenu: state.toggleMenu,
    showRecap: state.showRecap,
    toggleShowRecap: state.toggleShowRecap,
    menuOpened: state.menuOpened,
  }));

  const emersyaViewerContext = useContext(EmersyaViewerContext);
  const viewer = emersyaViewerContext.viewer;

  return (
    <div className={styles.container}>
      <Inline align="space-between" verticalAlign="center">

        <div className={styles.virtualTourButton}>
          {showRecap
            ? <TextIconButton icon="back" text={t('button.backToConfigurator')} onClick={toggleShowRecap} />
            : <TextIconButton icon="virtualTour" text={t('button.virtualTryOn')} onClick={() => viewer.startAr({startInNewPage : 'newTab'})} />
          }
        </div>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.themeButton} >
          <TextIconButton icon="theme" text={t('button.changeTheme')} onClick={toggleTheme} />
        </div>
        {menuOpened
          ? <XIcon className={styles.menuButton} onClick={toggleMenu} />
          : <HamburgerIcon className={styles.menuButton} onClick={toggleMenu} />
        }
      </Inline>
    </div>
  );
};
