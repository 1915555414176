const camera = {
  linguetta: {
    "position": [-12.549778938293457,5.911828994750977,-0.07554356753826141],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0, 1, 0],
    "fov": 25,
    "transitionTime": 1000
  },
  knit: {
    "position": [-10.228785514831543,7.752980709075928,5.360328674316406],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0, 1, 0],
    "fov": 25,
    "transitionTime": 1000
  },
  skin: {
    "position": [-0.4107059836387634,0.8732089400291443,13.091996192932129],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0, 1, 0],
    "fov": 25,
    "transitionTime": 1000
  },
  retrobanda: {
    "position": [12.633447647094727,1.6380752325057983,0.41533684730529785],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0,1,0],
    "fov": 25,
    "transitionTime": 1000
  },
  retrologo: {
    "position": [12.189169883728027,1.907339096069336,-3.3517422676086426],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0, 1, 0],
    "fov": 25,
    "transitionTime": 1000
  },
  passacavi: {
    "position": [-10.228785514831543,7.752980709075928,5.360328674316406],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0, 1, 0],
    "fov": 25,
    "transitionTime": 1000
  },
  /*boa: {
    "position": [-0.05742749944329262,0.241374209523201,-0.36720964312553406],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 35,
    "transitionTime": 1000
  },*/
  lacci: {
    "position": [-7.622757911682129,11.851057052612305,-0.06227882578969002],
    "target": [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    "up": [0, 1, 0],
    "fov": 25,
    "transitionTime": 1000
  },
};

export default {
  name: "POGIS",
  price: "680€",
  id: "NOQDI1EF1U",
  screenshotCamera: {
    position: [-9.935389518737793,7.005877494812012,6.637374401092529],
    target: [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    up: [0, 1, 0]
  },
  screenshotCamera2: {
    position: [10.106071472167969,4.660916328430176,-6.813121795654297],
    target: [-0.44526493549346924,0.8834107518196106,-0.015504032373428345],
    up: [0, 1, 0]
  },
  configurableGroups: [
    {
      id: 25,
      name: "LINGUETTA",
      label: "Linguetta",
      selectorType: "color",
      camera: camera.linguetta,
      configurations: [
        {
          id: 82,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'KNI201',
        },
        {
          id: 83,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'KNI202',
        },
        {
          id: 84,
          name: "Grigio",
          color: "rgb(146, 146, 146)",
          code: 'KNI203',
        },
        {
          id: 85,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'KNI205',
        },
        {
          id: 86,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'KNI204',
        },
        {
          id: 128,
          name: "Celestino",
          color: "#b8d9e3",
          code: 'KNI206',
        },
        {
          id: 124,
          name: "Azzurro",
          color: "#0085a6",
          code: 'KNI207',
        },
        {
          id: 123,
          name: "Mattone",
          color: "#b34024",
          code: 'KNI208',
        },
        {
          id: 125,
          name: "Rosa Fluo",
          color: "#e81741",
          code: 'KNI209',
        },
        {
          id: 126,
          name: "Rosa Giro",
          color: "#fa85a8",
          code: 'KNI213',
        },
        {
          id: 126,
          name: "Lilla",
          color: "#a99dd0",
          code: 'KNI210',
        },
        {
          id: 127,
          name: "Giallo",
          color: "rgb(231, 186, 52)",
          code: 'KNI212',
        },
      ]
    },
    {
      id: 22,
      name: "KNIT",
      label: "KNIT",
      selectorType: "color",
      camera: camera.knit,
      configurations: [
        {
          id: 116,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'KNI101',
        },
        {
          id: 117,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'KNI102',
        },
        {
          id: 118,
          name: "Grigio",
          color: "rgb(170, 170, 170)",
          code: 'KNI103',
        },
        {
          id: 119,
          name: "Giallo",
          color: "rgb(231, 186, 52)",
          code: 'KNI107',
        },
        {
          id: 120,
          name: "Coral",
          color: "rgb(250, 93, 72)",
          code: 'KNI106',
        },
        {
          id: 121,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'KNI104',
        },
        {
          id: 122,
          name: "Blu",
          color: "rgb(0, 60, 130)",
          code: 'KNI105',
        },
        {
          id: 128,
          name: "Celestino",
          color: "#b8d9e3",
          code: 'KNI108',
        },
        {
          id: 124,
          name: "Azzurro",
          color: "#0085a6",
          code: 'KNI109',
        },
        {
          id: 123,
          name: "Mattone",
          color: "#b34024",
          code: 'KNI110',
        }, {
          id: 125,
          name: "Rosa Fluo",
          color: "#e81741",
          code: 'KNI111',
        },
        {
          id: 126,
          name: "Rosa Giro",
          color: "#fa85a8",
          code: 'KNI114',
        },
        {
          id: 127,
          name: "Lilla",
          color: "#a99dd0",
          code: 'KNI112',
        },
      ]
    },
    {
      id: 23,
      name: "SKIN",
      label: "SKIN",
      selectorType: "color",
      camera: camera.skin,
      configurations: [
        {
          id: 123,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'SKI001',
        },
        {
          id: 124,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'SKI002',
        },
        {
          id: 125,
          name: "Grigio",
          color: "rgb(122, 122, 122)",
          code: 'SKI003',
        },
        {
          id: 126,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'SKI005',
        },
        {
          id: 127,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'SKI004',
        },
        {
          id: 128,
          name: "Giallo",
          color: "rgb(231, 186, 52)",
          code: 'SKI006',
        }
      ]
    },
    {
      id: 33,
      name: "RETRO BANDA",
      label: "Foxing",
      selectorType: "color",
      camera: camera.retrobanda,
      configurations: [
        {
          id: 153,
          name: "Orange",
          color: "rgb(255, 115, 0)",
          code: 'FOX006',
        },
        {
          id: 154,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'FOX001',
        },
        {
          id: 155,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'FOX002',
        },
        {
          id: 156,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'FOX004',
        },
        {
          id: 157,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'FOX005',
        },
        {
          id: 158,
          name: "Grigio",
          color: "rgb(122, 122, 122)",
          code: 'FOX003',
        }
      ]
    },
    {
      id: 34,
      name: "RETRO LOGO",
      label: "Logo Foxing",
      selectorType: "color",
      camera: camera.retrologo,
      configurations: [
        {
          id: 159,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'TXL001',
        },
        {
          id: 160,
          name: "Antracite",
          color: "rgb(0, 0, 0)",
          code: 'TXL002',
        },
        {
          id: 161,
          name: "Orange",
          color: "rgb(255, 115, 0)",
          code: 'TXL003',
        }
      ]
    },
     {
       id: 26,
       name: "PASSACAVI",
       label: "Passacavi",
       selectorType: "color",
       camera: camera.passacavi,
       configurations: [{
           id: 152,
           name: "Bianco",
           color: "rgb(255, 255, 255)",
           code: 'PUL001',
         },
         {
           id: 87,
           name: "Nero",
           color: "rgb(0, 0, 0)",
           code: 'PUL002',
         }
       ]
     },
     /*,
     {
       id: 32,
       name: "BOA",
       label: "Boa",
       selectorType: "color",
       camera: camera.boa,
       configurations: [
         {
           id: 134,
           name: "Bianco",
           color: "rgb(255, 255, 255)",
           code: 'BOA001',
         },
         {
           id: 135,
           name: 'boanero',
           color: "rgb(0, 0, 0)",
           code: 'BOA002',
         },
         {
           id: 136,
           name: "Argento",
           color: "rgb(210, 210, 216)",
           code: 'BOA003',
         },
         {
           id: 137,
           name: "Blu",
           color: "rgb(32, 112, 183)",
           code: 'BOA005',
         },
         {
           id: 138,
           name: "Rosso",
           color: "rgb(160, 0, 50)",
           code: 'BOA004',
         },
         {
           id: 139,
           name: "Rame",
           color: "rgb(176 141 87)",
           code: 'BOA006',
         },
       ]
     },*/
    {
      id: 30,
      name: "LACCI",
      label: "Lacci",
      selectorType: "color",
      camera: camera.lacci,
      configurations: [
        {
          id: 108,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'ACL001',
        },
        {
          id: 109,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'ACL002',
        },
        {
          id: 111,
          name: "Arancio",
          color: "rgb(255, 115, 0)",
          code: 'ACL004',
        },
        {
          id: 112,
          name: "Viola",
          color: "#8000d9",
          code: 'ACL005',
        }
      ]
    }
  ]
}
