import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import DetailRow from "./DetailRow";
import useAppStore from "../hooks/useAppStore";
import { DEFAULT_DETAIL } from "../templates/VariantSelector";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

export default function TeamCustomization({
  items,
  ...props
}) {
  
  const ref = useRef();
  
  const { t, i18n } = useTranslation();
  
  const {
    details, addDetail,
    removeDetail, updateDetail,
    toggleShowTeamCustomization, toggleShowRecap
  } = useAppStore( state => ({
    details: state.details,
    addDetail: state.addDetail,
    removeDetail: state.removeDetail,
    updateDetail: state.updateDetail,
    toggleShowTeamCustomization: state.toggleShowTeamCustomization,
    toggleShowRecap: state.toggleShowRecap
  }));
  
  const onChange = ( (row, detail)  => {
    const idx = row-1;
    updateDetail({
      ...details[idx],
      ...detail
    }, idx);
  });
  
  const completedRows = details.filter( detail => (
    detail.size !== null
  )).length;
  
  const detailsCompleted = completedRows === details.length;
  
  return (
    <Stack style={{width: '55.56rem'}}>
      <div style={{height: '24.68rem', overflowY: "auto", width: "100%"}} ref={ref}>
        <Stack gap="large">
          {details.map( (detail, idx) => (
            <DetailRow
              key={idx}
              item={detail}
              row={idx + 1}
              onDelete={ () => removeDetail(idx) }
              onChange={onChange}
            />
          ))}
        </Stack>
      </div>
      <Inline align="space-between" gap="medium" style={{width: '100%'}}>
        <Inline>
          <Label size="medium" weight="strong" color="#ff7500">
            {completedRows}/{details.length}
          </Label>
          <Label size="medium" weight="weak">Utenti inseriti</Label>
        </Inline>
        <Button
          disabled={! detailsCompleted}
          onClick={ (event) => {
            toggleShowTeamCustomization();
            toggleShowRecap();
          }}
        >
          {t('button.confirm')}
        </Button>
        <Button
          primary={false}
          onClick={ (event) => {
            addDetail(DEFAULT_DETAIL);
            setTimeout(function(){
              const y = ref.current.scrollHeight - ref.current.clientHeight;
              ref.current.scroll({
                top: y,
                left: 0,
                behavior: "smooth"
              });
            }, 200);
          }}>
          ADD NEW MEMBER
        </Button>
      </Inline>
    </Stack>
  )
  
}