import React from "react";
import EmersyaViewerContext from "./contexts/EmersyaViewerContext";
import useAppStore from "./hooks/useAppStore";
import useEmersya from "./hooks/useEmersya";

export default function EmersyaViewerProvider({
  children,
  ...props
}) {

  const modelConfiguration = useAppStore( state => state.modelConfiguration);

  const [viewer, loaded, progress] = useEmersya({
    id: modelConfiguration.id,
    containerId: 'dmt3dConfigurator',
  });

  return (
    <EmersyaViewerContext.Provider value={{viewer, loaded, progress}}>
      {children}
    </EmersyaViewerContext.Provider>
  );

}
