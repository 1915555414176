import {useRef} from "react";
import storeCreator from "./stores/storeCreator";
import StoreContext from "./contexts/StoreContext";
import { DEFAULT_MODEL_ID } from "./configurations/models";

export default function StoreProvider({
  options,
  children,
  ...props
}) {
  
  const modelId = options.modelId || DEFAULT_MODEL_ID;
  if (options.modelId === undefined ) {
    console.log(`modelId: ${options.modelId} not available. Using default ${DEFAULT_MODEL_ID}`);
  }
  
  const storeRef = useRef();

  if (!storeRef.current) {
    storeRef.current = storeCreator({
      ...options,
      modelId,
      ...props
    });
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  )

}
