export const createDmtConfiguratorSlice = (set) => ({
  /* example [{
    group : { id: 25, name: "LINGUETTA", label: "Linguetta", selectorType: 'color'},
    configuration: { id: 83, name: "Nero", color: "rgb(0, 0, 0)"}
  }]*/
  currentMaterials: [],
  setCurrentMaterials: (items) => set({currentMaterials: items}),
  
  details: [],
  setDetails: (items) => set({details: items}),
  
  addDetail: (item) => set( state => ({
    details: [...state.details, item]
  })),
  
  updateDetail: (item, idx) => set( state => {
    const nextDetails = [...state.details];
    nextDetails.splice(idx, 1, item);
    return {
      details: [...nextDetails]
    };
  }),
  
  removeDetail: (index) => set( state => {
    const nextDetails = [...state.details];
    nextDetails.splice(index, 1);
    return {
      details: [...nextDetails]
    };
  }),
  
  textColor: null,
  setTextColor: (item) => set({textColor: item}),
  
  flagOutlineColor: null,
  setFlagOutlineColor: (item) => set({flagOutlineColor: item}),
});
/*
* name: "Bianco",
  color: "rgb(255, 255, 255)",
  code: 'TXT01',
 */