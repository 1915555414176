.circle {
  border-radius: 50%;
  box-sizing: border-box;
}

.small {
  composes: circle;
  width: .625rem;
  height: .625rem;
}

.medium {
  composes: circle;
  width: 1.5625rem;
  height: 1.5625rem;
}
