.inline {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: center;
}

.gap-normal {
  composes: inline;
  composes: gap-normal from '../style.module.css';
}

.gap-medium {
  composes: inline;
  composes: gap-medium from '../style.module.css';
}

.gap-large {
  composes: inline;
  composes: gap-large from '../style.module.css';
}

.gap-xlarge {
  composes: inline;
  composes: gap-xlarge from '../style.module.css';
}

.valign-start {
  composes: inline;
  align-items: flex-start;
}

.valign-center {
  composes: inline;
  align-items: center;
}

.valign-end {
  composes: inline;
  align-items: flex-end;
}

.halign-start {
  composes: inline;
  justify-content: flex-start;
}

.halign-center {
  composes: inline;
  justify-content: center;
}

.halign-end {
  composes: inline;
  justify-content: flex-end;
}

.halign-space-between {
  composes: inline;
  justify-content: space-between;
}

.reverse {
  composes: inline;
  flex-direction: row-reverse;
}
