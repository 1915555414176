import * as React from "react"

export default (props) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 1396 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M560.3 0.5C680 0.5 799.7 0.5 919.5 0.5C922.6 0.8 925.7 1.20001 928.6 2.20001C934.8 4.40001 940.1 9.60002 942.9 15.9C945.6 22.2 945.7 29.7 943 35.7C941.7 38.5 939.8 41 937.9 43.5C894.7 88.8 851.5 134.2 808.3 179.5C808.1 179.5 807.9 179.5 807.7 179.5C806.3 179.5 804.8 179.5 803.4 179.5C801.5 179.5 799.7 179.5 797.8 179.5C795.6 179.5 793.4 179.5 791.2 179.5C788.8 179.5 786.4 179.5 784 179.5C781.4 179.5 778.9 179.5 776.3 179.5C773.7 179.5 771.1 179.5 768.5 179.5C765.9 179.5 763.4 179.5 760.8 179.5C758.4 179.5 756 179.5 753.5 179.5C751.3 179.5 749.1 179.5 746.9 179.5C745.1 179.5 743.2 179.5 741.3 179.5C739.8 179.5 738.3 179.5 737 179.5C736.2 179.5 735.4 179.4 734.2 179.5C733.8 179.5 733.3 179.6 733.2 179.5C733.2 179.4 733.5 179.1 733.9 178.8C734.8 178 735.3 177.4 735.8 176.8C736.7 175.8 737.8 174.7 738.8 173.6C740.2 172.1 741.5 170.7 742.8 169.3C744.4 167.6 746 165.9 747.6 164.1C749.5 162.1 751.3 160.1 753.2 158.2C755.2 156 757.3 153.8 759.3 151.6C761.5 149.2 763.7 146.9 765.9 144.5C768.2 142 770.5 139.6 772.8 137.1C775.2 134.6 777.5 132 779.9 129.5C782.3 126.9 784.7 124.4 787.1 121.8C788.7 120.1 790.3 118.4 791.9 116.7C813.2 94.3 834.6 71.9 855.9 49.6C856.5 48.8 857.2 47.9 857.6 47C858.5 45 858.5 42.5 857.6 40.4C856.7 38.3 854.9 36.5 852.8 35.8C851.8 35.5 850.8 35.4 849.8 35.2C822.2 35.2 794.6 35.2 767.1 35.2C721.3 83.2 675.5 131.3 629.8 179.3C607.6 179.3 585.5 179.3 563.3 179.3C609.1 131.3 654.9 83.2 700.6 35.2C668.6 35.2 636.7 35.2 604.7 35.2C558.9 83.3 513.1 131.3 467.3 179.4C441.5 179.4 415.6 179.4 389.8 179.4C446.6 119.8 503.4 60.1 560.3 0.5Z"
      fill="currentColor"/>
    <path
      d="M484.2 16C481.5 9.7 476.1 4.39999 469.9 2.29999C467 1.29999 463.9 0.999976 460.8 0.599976C364 0.599976 267.2 0.599976 170.5 0.599976C113.7 60.2 56.8 119.9 0 179.5C102 179.5 204.1 179.5 306.1 179.5C310.6 179.3 315.2 179.1 319.7 178.6C328.8 177.5 337.7 174.9 346.2 171.3C354.7 167.7 362.7 163 369.7 157.2C373.2 154.3 376.5 151.2 379.7 148C412.9 113.2 446 78.4 479.2 43.6C481.1 41.1 483 38.6 484.3 35.8C487 29.8 486.9 22.3 484.2 16ZM402.7 42.7C402.1 44.1 401.1 45.3 400.2 46.6C370 78.3 339.8 110 309.6 141.7C308.2 143 306.9 144.3 305.4 145.5C302.5 147.9 299.1 149.9 295.6 151.4C292.1 152.9 288.4 154 284.6 154.4C282.7 154.6 280.8 154.7 278.9 154.8C219.1 154.8 159.4 154.8 99.6 154.8C140.8 111.6 182 68.3 223.2 25.1C279.1 25.1 335 25.1 391 25.1C392.6 25.3 394.1 25.4 395.6 25.9C398.7 27 401.4 29.6 402.7 32.8C404.1 36 404.1 39.7 402.7 42.7Z"
      fill="currentColor"/>
    <path
      d="M978.8 0.400024C971 8.60002 963.2 16.8 955.4 25C1000.8 25 1046.2 25 1091.6 25C1042.6 76.4 993.6 127.9 944.5 179.3C969.1 179.3 993.6 179.3 1018.2 179.3C1067.2 127.9 1116.2 76.4 1165.3 25C1204.5 25 1206.5 25 1238.1 25.1L1260.2 0.400024C1153.8 0.400024 1088.2 0.400024 978.7 0.400024H978.8Z"
      fill="currentColor"/>
    <path
      d="M1370.3 25.1H1263.2L1284.9 0.400024H1396V53.3C1396 61.5 1394.8 68.8 1392.5 75.3C1390.2 81.8 1386.8 87.3 1382.2 91.8C1377.7 96.3 1372.2 99.7 1365.7 102.1C1359.2 104.5 1351.7 105.7 1343.4 105.7H1263.2V41.3H1288.9V80.8H1342.9C1361.2 80.8 1370.4 71.5 1370.4 52.9V25.3L1370.3 25.1Z"
      fill="url(#paint0_linear_2_23)"/>
    <path d="M1257.7 41.2H1235.3V105.6H1257.7V41.2Z" fill="url(#paint1_linear_2_23)"/>
    <path
      d="M1235.5 25.1C1239.1 25.1 1248.4 25.1 1252.8 25.1C1260.6 16.9 1267.2 8.60002 1275 0.400024C1270.9 0.400024 1261.6 0.400024 1257.7 0.400024L1235.6 25.1H1235.5Z"
      fill="url(#paint2_linear_2_23)"/>
    <defs>
      <linearGradient id="paint0_linear_2_23" x1="1203.7" y1="31.8" x2="1404.7" y2="65.8"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#9F8DBE"/>
        <stop offset="0.2" stop-color="#A65674"/>
        <stop offset="0.4" stop-color="#AD1E2A"/>
        <stop offset="0.6" stop-color="#674075"/>
        <stop offset="1" stop-color="#365EA8"/>
      </linearGradient>
      <linearGradient id="paint1_linear_2_23" x1="1198.1" y1="65.2" x2="1399.1" y2="99.1997"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#9F8DBE"/>
        <stop offset="0.2" stop-color="#A65674"/>
        <stop offset="0.4" stop-color="#AD1E2A"/>
        <stop offset="0.6" stop-color="#674075"/>
        <stop offset="1" stop-color="#365EA8"/>
      </linearGradient>
      <linearGradient id="paint2_linear_2_23" x1="1208.3" y1="4.80002" x2="1409.3" y2="38.8001"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#9F8DBE"/>
        <stop offset="0.2" stop-color="#A65674"/>
        <stop offset="0.4" stop-color="#AD1E2A"/>
        <stop offset="0.6" stop-color="#674075"/>
        <stop offset="1" stop-color="#365EA8"/>
      </linearGradient>
    </defs>
  </svg>
);