import {useEffect} from "react";
import useAppStore from "./useAppStore";

const MATERIAL_SELECTORS = ['color'];

export default function useMeshSelection(viewer, loaded) {

  const {modelConfiguration, setCurrentGroup, toggleMenu, currentMaterials} = useAppStore( (state) => ({
    setCurrentGroup: state.setCurrentGroup,
    modelConfiguration: state.modelConfiguration,
    toggleMenu: state.toggleMenu,
    currentMaterials: state.currentMaterials,
  }));

  useEffect(function (){
    if(viewer === null) return;
    if(loaded === false) return;

    function listener(e){

      function getDisabled(index) {
        if (index === 0) return false;

        return currentMaterials[index - 1] === undefined;
      }

      const material = e.configurableMaterials[0];
      const index = modelConfiguration.configurableGroups.findIndex( group => group.name === material);

      if(
          index >= 0 &&
          !getDisabled(index)
      ) {
        const selectedGroup = modelConfiguration.configurableGroups[index];
        setCurrentGroup(selectedGroup);
      }
    }

    viewer.addEventListener('onMeshSelection', listener, false);

    return function() {
      viewer.removeEventListener('onMeshSelection', listener, false);
    };

  }, [viewer, loaded, currentMaterials]);

}
