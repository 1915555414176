import useScript from "./useScript";
import {useEffect, useRef, useState} from "react";
import useAppStore from "./useAppStore";

export default function useEmersya({
  id,
  containerId,
  lang = 'en-US'
}) {
  
  // https://cdn.emersya.com/f/emersyaLoader.js
  const emersyaScriptReady = useScript("https://cdn.emersya.com/f/dmtEmersyaLoader.js", {
    lang,
    routing: id,
    containerId,
  });

  const [initialized, setInitialized] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const emersyaViewer = useRef(null);
  const currentMaterials = useAppStore( (state) => state.currentMaterials);

  useEffect(function(){
    if(emersyaScriptReady === false) return;

    function listener(){
      emersyaViewer.current = window.emViewers['dmt3dConfigurator'];
      setInitialized(true);
    }

    document.addEventListener('emersyaViewerInitialized', listener, false);

    return function() {
      document.removeEventListener('emersyaViewerInitialized', listener, false);
    };
  }, [emersyaScriptReady]);


  useEffect(function(){
    if(! initialized) return;

    function listener(data){

      if(data.viewerState === 'loaded') {
        if(currentMaterials.length > 0) {
          const filteredCurrentMaterial = currentMaterials.filter(material => ['color'].indexOf(material.group.selectorType) >= 0);
          emersyaViewer.current.setMaterialsGroups({
            configurations: filteredCurrentMaterial.map(material => ({
              configurationName: material.configuration.name,
              groupName: material.group.name,
            }))
          })
            .then( data => {
              //emersyaViewer.current.hideHelp();

              setTimeout(function(){
                setProgress(100);
                setLoaded(true);
              }, 1500);
            })
            .error( err => console.warn(err));
          return;
        }

        setProgress(100);
        setLoaded(true);
        return;
      }

      if(data.viewerState !== 'started') {
        setProgress(parseFloat(data.loadingProgress) * 100);
      }
    }

    emersyaViewer.current.addEventListener("onStateChange", listener);

    return function() {
      emersyaViewer.current.removeEventListener('onStateChange', listener, false);
    };

  }, [initialized]);


  return [emersyaViewer.current, loaded, progress];

}
