import * as styles from "./Navigation.module.css";
import Inline from "../atoms/Inline";
import BackIcon from "../icons/BackIcon";
import Label from "../atoms/Label";
import Button from "../atoms/Button";
import ResetIcon from "../icons/ResetIcon";
import useAppStore from "../hooks/useAppStore";
import Progress from "../atoms/Progress";
import {itemStatus} from "./ConfigurationItem";
import {useTranslation} from "react-i18next";
import { useMemo } from "react";
import modes from "../configurations/modes";

export default function Navigation({
  ...props
}) {

  const { t, i18n } = useTranslation();

  const {
    modelConfiguration, currentMaterials, currentGroup, setCurrentGroup,
    toggleShowRecap, toggleResetModal, details, mode,
    textColor, toggleShowTeamCustomization, flagOutlineColor, modelId
  } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    setCurrentGroup: state.setCurrentGroup,
    currentGroup: state.currentGroup,
    currentMaterials: state.currentMaterials,
    setCurrentMaterials: state.setCurrentMaterials,
    toggleResetModal: state.toggleResetModal,
    toggleShowRecap: state.toggleShowRecap,
    details: state.details,
    mode: state.mode,
    textColor: state.textColor,
    flagOutlineColor: state.flagOutlineColor,
    toggleShowTeamCustomization: state.toggleShowTeamCustomization,
    modelId: state.modelId,
  }));
  
  
  // b2b,  FLAG, TEXT and SIZE has to be selected for all elements in details array
  const detailsCompleted = useMemo(function () {
    return details.every( detail =>
      //detail.text !== '' &&
      //detail.flag !== null &&
      detail.size !== null
    );
  }, [details]);
  
  const flagSelected = useMemo(function(){
    return details.length > 0 && details[0].flag !== null;
  }, [details]);
  
  const textSelected = useMemo(function(){
    return details.length > 0 && details[0].text !== '';
  }, [details]);
  
  const nameSelected = useMemo(function(){
    return textColor !== null && textSelected;
  }, [textColor, textSelected]);
  
  const sizeSelected = useMemo(function(){
    return details.length > 0 && details[0].size !== null;
  }, [details]);
  
  const completed =
    mode === modes.B2B
    ? currentMaterials.length === 8 && detailsCompleted
    : (modelId === 'masterSkuPogis')
        ? currentMaterials.length === 7 // POGIS has no BOA
        : currentMaterials.length === 8;
  
  const index = modelConfiguration.configurableGroups.findIndex( group => currentGroup && group.name === currentGroup.name);
  const first = index === 0;
  const currentIndex = modelConfiguration.configurableGroups.findIndex(group => currentGroup && group.name === currentGroup.name);
  const last = currentIndex === modelConfiguration.configurableGroups.length - 1;
  
  const currentGroupStatus = currentMaterials.find( material => currentGroup && material.group.name === currentGroup.name) ||
      (currentGroup && currentGroup.name === 'TAGLIA' && sizeSelected) ||
      (currentGroup && currentGroup.name === 'BANDIERA' && (
        !flagSelected || (flagSelected && flagOutlineColor !== null)
      )) ||
      (currentGroup && currentGroup.name === 'NOME' &&
        (!textSelected || nameSelected)
      ) ||
      (currentGroup && currentGroup.name === 'QUANTITÀ' && details.length > 0)
        ? itemStatus.done
        : itemStatus.todo

  const buttonContainerClassName = `${styles.buttonContainer} ${first ? styles.disabled : null}`;
  const buttonResetContainerClassName = `${styles.buttonContainer} ${currentMaterials.length === 0 ? styles.disabled : null}`;

  const total = modelConfiguration.configurableGroups.length;
  const progress = ( (currentIndex+1) * 100) / total;
  //const progress = ( (currentMaterials.length) * 100) / total;

  const back = () => {
    if(currentIndex <= 0) return;
    setCurrentGroup(modelConfiguration.configurableGroups[currentIndex - 1]);
  };

  const next = () => {
    
    //All steps completed, show purchase page
    if(last && completed && mode === modes.B2C) {
      toggleShowRecap();
      return;
    }
    
    // always show team customization modal to allow user to modify details
    if(last && mode === modes.B2B) {
      toggleShowTeamCustomization();
      return;
    }
    
    if(currentIndex === modelConfiguration.configurableGroups.length - 1) return;

    setCurrentGroup(modelConfiguration.configurableGroups[currentIndex + 1]);
  };

  return (
    <>
      <div className={styles.progress}>
        <Progress progress={progress}/>
      </div>

      <div className={styles.navigation}>

        <Inline align="space-between" verticalAlign="center">
          <div className={buttonContainerClassName}>
            <button
              onClick={back}
              disabled={first}
              className={styles.button}
            >
              <Inline verticalAlign="center">
                <BackIcon />
                <div className={styles.backLabel}>
                  <Label>{t('button.back')}</Label>
                </div>
              </Inline>
            </button>
          </div>

          <Button
            disabled={currentMaterials.length === 0 || currentGroupStatus === itemStatus.todo}
            onClick={next}
          >
            {t('button.next')}
          </Button>

          <div className={buttonResetContainerClassName}>
            <button
              onClick={toggleResetModal}
              disabled={currentMaterials.length === 0}
              className={styles.button}
            >
              <Inline verticalAlign="center">
                <div className={styles.resetLabel}>
                  <Label>{t('button.reset')}</Label>
                </div>
                <ResetIcon/>
              </Inline>
            </button>
          </div>

        </Inline>
      </div>
    </>
  );
}
