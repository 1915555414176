const camera = {
  linguetta: {
    "position": [-0.3561944365501404,0.22212594747543335,-0.23641741275787354],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  knit: {
    "position": [-0.2630605697631836,0.2644195258617401,-0.29243871569633484],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  skin: {
    "position": [-0.46496403217315674,0.06311455368995667,-0.08199693262577057],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  retrobanda: {
    "position": [-0.05979924649000168,0.07231391221284866,0.35669267177581787],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0,1,0],
    "fov": 30,
    "transitionTime": 1000
  },
  retrologo: {
    "position": [0.022560030221939087,0.07231613248586655,0.3442235589027405],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  passacavi: {
    "position": [-0.2630605697631836,0.2644195258617401,-0.29243871569633484],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  boa: {
    "position": [-0.05742749944329262,0.241374209523201,-0.36720964312553406],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 35,
    "transitionTime": 1000
  },
  lacci: {
    "position": [-0.07731963694095612,0.3503328263759613,-0.26845458149909973],
    "target": [-0.07674174010753632,0.036391083151102066,-0.0334484837949276],
    "up": [0, 1, 0],
    "fov": 35,
    "transitionTime": 1000
  },
};

export default {
  name: "XXX0",
  price: "680€",
  id: "SN9ZXZ6FVR", //"A5GYF8PJ25",//"XPJB7Z33J9",
  screenshotCamera: {
    position: [-0.32752349972724915,0.2387365996837616,-0.25163301825523376],
    target: [-0.06577038764953613,0.03663841634988785,-0.04085046425461769],
    up: [0, 1, 0]
  },
  screenshotCamera2: {
    position: [0.1795460730791092,0.17320281267166138,0.24116633832454681],
    target: [-0.07688124477863312,0.03663841634988785,-0.028472349047660828],
    up: [0, 1, 0]
  },
  configurableGroups: [
    {
      id: 25,
      name: "LINGUETTA",
      label: "Linguetta",
      selectorType: "color",
      camera: camera.linguetta,
      configurations: [
        {
          id: 82,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'KNI201',
        },
        {
          id: 83,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'KNI202',
        },
        {
          id: 84,
          name: "Grigio",
          color: "rgb(146, 146, 146)",
          code: 'KNI203',
        },
        {
          id: 85,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'KNI205',
        },
        {
          id: 86,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'KNI204',
        },
        {
          id: 128,
          name: "Celestino",
          color: "#b8d9e3",
          code: 'KNI206',
        },
        {
          id: 124,
          name: "Azzurro",
          color: "#0085a6",
          code: 'KNI207',
        },
        {
          id: 123,
          name: "Mattone",
          color: "#b34024",
          code: 'KNI208',
        }, 
        {
          id: 125,
          name: "Rosa",
          color: "#ff5b7d",
          code: 'KNI209',
        },
        {
          id: 126,
          name: "Lilla",
          color: "#a99dd0",
          code: 'KNI210',
        },
      ]
    },
    {
      id: 22,
      name: "KNIT",
      label: "KNIT",
      selectorType: "color",
      camera: camera.knit,
      configurations: [
        {
          id: 116,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'KNI101',
        },
        {
          id: 117,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'KNI102',
        },
        {
          id: 118,
          name: "Grigio",
          color: "rgb(170, 170, 170)",
          code: 'KNI103',
        },
        {
          id: 119,
          name: "Giallo",
          color: "rgb(231, 186, 52)",
          code: 'KNI107',
        },
        {
          id: 120,
          name: "Coral",
          color: "rgb(250, 93, 72)",
          code: 'KNI106',
        },
        {
          id: 121,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'KNI104',
        },
        {
          id: 122,
          name: "Blu",
          color: "rgb(0, 60, 130)",
          code: 'KNI105',
        },
        {
          id: 128,
          name: "Celestino",
          color: "#b8d9e3",
          code: 'KNI108',
        },
        {
          id: 124,
          name: "Azzurro",
          color: "#0085a6",
          code: 'KNI109',
        },
        {
          id: 123,
          name: "Mattone",
          color: "#b34024",
          code: 'KNI110',
        }, {
          id: 125,
          name: "Rosa",
          color: "#ff5b7d",
          code: 'KNI111',
        },
        {
          id: 126,
          name: "Lilla",
          color: "#a99dd0",
          code: 'KNI112',
        },
      ]
    },
    {
      id: 23,
      name: "SKIN",
      label: "SKIN",
      selectorType: "color",
      camera: camera.skin,
      configurations: [
        {
          id: 123,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'SKI001',
        },
        {
          id: 124,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'SKI002',
        },
        {
          id: 125,
          name: "Grigio",
          color: "rgb(122, 122, 122)",
          code: 'SKI003',
        },
        {
          id: 126,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'SKI005',
        },
        {
          id: 127,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'SKI004',
        }
      ]
    },
    {
      id: 33,
      name: "RETRO BANDA",
      label: "Foxing",
      selectorType: "color",
      camera: camera.retrobanda,
      configurations: [
        {
          id: 153,
          name: "Arancio",
          color: "rgb(255, 115, 0)",
          code: 'FOX006',
        },
        {
          id: 154,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'FOX001',
        },
        {
          id: 155,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'FOX002',
        },
        {
          id: 156,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'FOX004',
        },
        {
          id: 157,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'FOX005',
        },
        {
          id: 158,
          name: "Grigio",
          color: "rgb(122, 122, 122)",
          code: 'FOX003',
        }
      ]
    },
    {
      id: 34,
      name: "RETRO LOGO",
      label: "Logo Foxing",
      selectorType: "color",
      camera: camera.retrologo,
      configurations: [
        {
          id: 159,
          name: "BIANCO",
          color: "rgb(255, 255, 255)",
          code: 'TXL001',
        },
        {
          id: 160,
          name: "Antracite",
          color: "rgb(0, 0, 0)",
          code: 'TXL002',
        },
        {
          id: 161,
          name: "ORANGE",
          color: "rgb(255, 115, 0)",
          code: 'TXL003',
        }
      ]
    },
    {
      id: 26,
      name: "PASSACAVI",
      label: "Passacavi",
      selectorType: "color",
      camera: camera.passacavi,
      configurations: [
        {
          id: 87,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'ACP002',
        },
        {
          id: 88,
          name: "Grigio",
          color: "rgb(170, 170, 170)",
          code: 'ACP003',
        },
        {
          id: 89,
          name: "Blu",
          color: "rgb(0, 116, 216)",
          code: 'ACP005',
        },
        {
          id: 90,
          name: "Rosso",
          color: "rgb(185, 10, 29)",
          code: 'ACP004',
        },
        {
          id: 152,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'ACP001',
        }
      ]
    },
    {
      id: 32,
      name: "BOA",
      label: "Boa",
      selectorType: "color",
      camera: camera.boa,
      configurations: [
        {
          id: 134,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'BOA001',
        },
        {
          id: 135,
          name: 'boanero',
          color: "rgb(0, 0, 0)",
          code: 'BOA002',
        },
        {
          id: 136,
          name: "Argento",
          color: "rgb(210, 210, 216)",
          code: 'BOA003',
        },
        {
          id: 137,
          name: "Blu",
          color: "rgb(32, 112, 183)",
          code: 'BOA005',
        },
        {
          id: 138,
          name: "Rosso",
          color: "rgb(160, 0, 50)",
          code: 'BOA004',
        },
        {
          id: 139,
          name: "Rame",
          color: "rgb(176 141 87)",
          code: 'BOA006',
        },
      ]
    },
    {
      id: 30,
      name: "LACCI",
      label: "Lacci",
      selectorType: "color",
      camera: camera.lacci,
      configurations: [
        {
          id: 108,
          name: "Bianco",
          color: "rgb(255, 255, 255)",
          code: 'ACL001',
        },
        {
          id: 109,
          name: "Nero",
          color: "rgb(0, 0, 0)",
          code: 'ACL002',
        },
        {
          id: 110,
          name: "Grigio",
          color: "rgb(129, 128, 127)",
          code: 'ACL003',
        }
      ]
    }
  ]
}
