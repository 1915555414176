import { useEffect, useState } from "react";
import useAppStore from "./useAppStore";
import flagsGroup from "../configurations/flagsGroup";

export default function useFlagText(viewer, loaded) {
  
  const {details, textColor, flagOutlineColor, modelId} = useAppStore( (state) => ({
    details: state.details,
    textColor: state.textColor,
    flagOutlineColor: state.flagOutlineColor,
    modelId: state.modelId
  }));
  
  useEffect(function (){
    if(viewer === null) return;
    if(loaded === false) return;
    
    const flag = details.length > 0
      ? details[0].flag
      : null;
    
    const text = details.length > 0
      ? details[0].text
      : null;
    
    const flagConfiguration = flag === null
      ? 'none'
      : flagsGroup[modelId].configurations.find( configuration => configuration.name === flag.toUpperCase()).materialName;
    
    let textMaterialVariation =
      textColor !== null
        ? textColor.name === 'Bianco'
            ? 'white'
            : 'black'
      : 'invisible';
    
    const flagMaterial = {
      materialVariation : flagConfiguration,
      configurableMaterial : 'Patch_flag'
    };
    
    const textMaterial = {
      materialVariation : textMaterialVariation,
      configurableMaterial : 'Patch_text'
    };
    
    let flagOutlineMaterialVariation =
      flagOutlineColor !== null
        ? flagOutlineColor.name === 'Bianco'
          ? 'white'
          : 'black'
        : 'invisible';
    
    const outlineMaterial = {
      materialVariation : (flag === null || flagOutlineColor === null) ? 'none' : flagOutlineMaterialVariation,
      configurableMaterial : 'Patch_flag_outline'
    };
    
    viewer.setMaterials({
      materials : [
        flagMaterial,
        textMaterial,
        outlineMaterial
      ]
    }).then( result => {
      
      if(textColor === null) return;
      
      viewer.updateCustomTextsRealDimensions({
        configurableMaterial: `Patch_text`,
        materialVariation: textMaterialVariation,
        contents: ['opacity'],
        color: '#ffffffff',
        backgroundColor: '#00000000',
        imageWidth: 2048,
        verticalAlignment: 'middle',
        textAlignment: 'static',
        horizontalAlignment: 'left',
        textOffsetX: 0,
        textOffsetY: 0,
        uvRatio: 4.15,
        surfaceWidth: 33.2,
        maxTextWidth: 33.2,
        maxTextHeight: 5,
        font: 'OpenSans-Semibold',
        mode: 'vertical',
        texts: [{
          color: textColor.color,
          verticalAlignment: 'middle',
          horizontalAlignment: 'left',
          offsetY: 0,
          offsetX: 0,
          text,
          font: 'OpenSans-Semibold',
          prespacing: 0,
          postspacing: 0
        }]
      })
        .then()
        .error(error => console.log(error))
    })
      .error(error => console.log(error));
    
  }, [viewer, loaded, details, textColor, flagOutlineColor]);
  
  
}
