.container {
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  transition: all 200ms ease-in;
}
.container:hover{
  overflow-x: auto;
}

.sizeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border: 2px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
}

.sizeContainer:hover {
  border: 1px solid #ff7500;
}

.selected {
  composes: sizeContainer;
  border: 1px solid #ff7500;
}

.container button {
  color: var(--main-container-foreground-color);
}
