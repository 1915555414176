import * as React from "react"

export default (props) => (
  <svg
    width={24}
    height={8}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g fill="currentColor" fillRule="evenodd">
      <path d="M0 7h24v1H0zM0 0h24v1H0z" />
    </g>
  </svg>
);
