import * as React from "react"

export default (props) => (
  <svg
    style={{width: '1.71rem', height: '1.71rem'}}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{"9B924031-C058-4A66-BE34-52CE2C373453"}</title>
    <path
      d="M21.841 13.423a.877.877 0 0 0-1.067.634c-1.07 4.178-4.817 7.096-9.112 7.096-5.188 0-9.408-4.245-9.408-9.463 0-5.217 4.22-9.462 9.408-9.462a9.31 9.31 0 0 1 6.673 2.79l-2.545-.053a.88.88 0 0 0-.895.863.88.88 0 0 0 .858.9l5.236.11.019.001c.27 0 .526-.126.693-.341a.886.886 0 0 0 .152-.778L20.448.646a.876.876 0 1 0-1.69.473l.704 2.542a11.248 11.248 0 0 0-2.792-2.007 10.996 10.996 0 0 0-5.008-1.19c-1.506 0-2.968.297-4.345.882a11.12 11.12 0 0 0-3.548 2.406A11.213 11.213 0 0 0 .5 11.69a11.213 11.213 0 0 0 3.27 7.938 11.12 11.12 0 0 0 3.548 2.406c1.377.586 2.839.883 4.345.883a11.078 11.078 0 0 0 6.891-2.395 11.295 11.295 0 0 0 3.92-6.025.882.882 0 0 0-.632-1.074"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
