import {default as Sel} from 'react-select'
import { components } from 'react-select'
import * as styles from './Select.module.css'
import * as flagStyles from './FlagSelector.module.css'
import useAppStore from "../hooks/useAppStore";

const commonStyles = {
  light: {
    foregroundColor: '#0A0A0A',
    backgroundColor: '#fff',
    optionBackgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  dark: {
    foregroundColor: '#fff',
    backgroundColor: '#0A0A0A',
    placeholderColor: 'rgb(230,230,230)',
    optionBackgroundColor: 'rgba(255, 255, 255, 0.08)',
  }
};

const Option = ({ data, ...props}) => (
  <components.Option {...props} >
    {data.icon && (
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '1rem' }}>
        <div className={`${flagStyles.flagSmall} ${flagStyles[data.icon.toLowerCase()]}`} />
      </div>
    )}
    <span>{data.label}</span>
  </components.Option>
);

const SingleValue = ({data, ...props}) => {
  return (
    <components.SingleValue {...props} >
      <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '.4rem' }}>
        {data.icon && (
          <div className={`${flagStyles.flagSmall} ${flagStyles[data.icon.toLowerCase()]}`} />
        )}
        {props.children}
      </div>
    </components.SingleValue>
  );
}

export default function Select({
 items,
 placeholder,
 selected,
 size = 'normal', // small
 onChange,
 ...props
}) {
  
  const theme = useAppStore( state => state.theme);
  
  return (
    <div className="container">
      <Sel
        placeholder={placeholder}
        options={items}
        value={selected}
        components={{ Option, SingleValue }}
        isSearchable
        onChange={onChange}
        menuPortalTarget={document.body}
        styles={{
          menuPortal: (base => ({
            ...base,
            zIndex: 12,
          })),
          input: (base) => ({
            ...base,
            color: commonStyles[theme].foregroundColor,
            marginLeft: '.4rem'
          }),
          placeholder: (base) => ({
            ...base,
            color: commonStyles[theme].placeholderColor,
          }),
          singleValue: (base) => ({
            color: commonStyles[theme].foregroundColor,
            fontSize: '.875rem',
          }),
          control: (base) => ({
            ...base,
            width: size === 'small' ? '6.8rem' : '14rem',
            borderColor: 'transparent',
            boxShadow: 'none',
            backgroundColor: 'transparent',
            borderBottom: '1px solid transparent',
            borderRadius: 0,
            borderLeft: 'none',
            borderRight: 'none',
            borderTop: 'none',
            outline: 'none',
            '&:hover': {
              borderColor: 'transparent',
              borderBottom: `1px solid ${commonStyles[theme].foregroundColor}`,
              boxShadow: 'none',
            }
          }),
          menu: (base) => ({
            ...base,
            backgroundColor: commonStyles[theme].backgroundColor,
            border: '1px solid transparent',
            padding: '.4rem',
            zIndex: 101,
          }),
          option: (styles, state) => ({
            ...styles,
            margin: '.4rem 0',
            borderRadius: '.25rem',
            display: 'flex',
            alignItems: 'center',
            gap: '.4rem',
            fontSize: '.875rem',
            color: commonStyles[theme].foregroundColor,
            backgroundColor: state.isSelected || state.isFocused
              ? commonStyles[theme].optionBackgroundColor
              : "transparent",
            "&:hover": {
              backgroundColor: commonStyles[theme].optionBackgroundColor,
            },
          }),
          valueContainer: (base) => ({
            ...base,
            display: 'flex',
          }),
        }}
      />
    </div>
  );
  
}