import React, {useContext, useEffect} from "react";
import MainTemplate from "../templates/MainTemplate";
import * as styles from "./Main.module.css";
import Loading from "../molecules/Loading";
import useAppStore from "../hooks/useAppStore";
import useCamera from "../hooks/useCamera";
import useSetMaterials from "../hooks/useSetMaterials";
import EmersyaViewerContext from "../contexts/EmersyaViewerContext";
import useMeshSelection from "../hooks/useMeshSelection";
import useMaterialHighlight from "../hooks/useMaterialHighlight";
import useViewerOverlayStatusUpdated
  from "../hooks/useViewerOverlayStatusUpdated";
import useFlagText from "../hooks/useFlagText";

export default function Main({
  ...props
 }) {

  const {viewer, loaded, progress} = useContext(EmersyaViewerContext);

  const {setCurrentGroup, modelConfiguration} = useAppStore( (state) => ({
    setCurrentGroup: state.setCurrentGroup,
    modelConfiguration: state.modelConfiguration
  }));

  const defaultCamera = modelConfiguration.configurableGroups[0].camera;

  useCamera(viewer, loaded);
  useSetMaterials(viewer, loaded);
  useMeshSelection(viewer, loaded);
  useMaterialHighlight(viewer, loaded);
  useViewerOverlayStatusUpdated(viewer, loaded);
  useFlagText(viewer, loaded);

  useEffect(function() {
    setCurrentGroup(modelConfiguration.configurableGroups[0]);
  },[]);

  useEffect(function() {
    if(viewer === null) return;
    if(loaded === false) return;

    viewer.pause().then( data => {
      const camera = modelConfiguration.configurableGroups[0].camera;
      if(camera === null) return;

      viewer.setCamera({
        camera : defaultCamera,
      })
        .then( data => console.log(data) )
        .error( err => console.warn(err));
    })
      .error( err => console.warn(err));

  },[viewer, loaded]);

  return (
      <MainTemplate>
        <div id="dmt3dConfigurator" className={styles.configurator}  />
        {loaded === false &&  (
          <div className={styles.overlay}>
            <div className={styles.loadingContainer}>
              <Loading progress={progress} />
            </div>
          </div>
        )}
      </MainTemplate>
  )
}
