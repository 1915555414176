import {useCallback, useEffect} from "react";
import useAppStore from "./useAppStore";

export default function useMaterialHighlight(viewer, loaded) {

  const {modelConfiguration, currentMaterials} = useAppStore( state => ( {
    currentMaterials: state.currentMaterials,
    modelConfiguration: state.modelConfiguration,
  }));

  useEffect(function () {
    if(viewer === null) return;
    if(loaded === false) return;

    function listener(e){
      function getDisabled(index) {
        if (index === 0) return false;

        return currentMaterials[index - 1] === undefined;
      }

      const material = e[0];
      const index = modelConfiguration.configurableGroups.findIndex( group => group.name === material);

      if(
        Array.isArray(e) && e.length === 0 || (
          index >= 0 &&
          !getDisabled(index)
        )
      ){
        viewer.setHighlight({
          meshes : [],
          configurableMaterials: e,
          configurableMaterialGroups : []
        });
      }

    }

    viewer.addEventListener('onConfigurableMaterialHighlight', listener, false);

    return function() {
      viewer.removeEventListener('onConfigurableMaterialHighlight', listener, false);
    };

  }, [viewer, loaded, currentMaterials]);

}
