import React from 'react';
import * as styles from "./Label.module.css";

export default function Label({
  size = 'normal',
  spacing = 'normal',
  weight = 'bold',
  color,
  children,
  ...props
}) {

  const className = `${styles[`size-${size}`]} ${styles[`spacing-${spacing}`]} ${styles[`weight-${weight}`]} `;

  return (
    <span className={className} style={{color}} {...props}>
      {children}
    </span>
  );
}
