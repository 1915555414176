.hidden {
  position: absolute;
  left: 100%;
  top: 100%;
}

.configurator {
  position: static;
  transform: translate(0,0);
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 12;
  inset: 0;
  padding: 0 1rem;
  background: var(--main-container-background-color);
}

.loadingContainer {
  width: 24rem;
}


