import * as React from "react"

export default (props) => (
  <svg
    width={28}
    height={28}
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        d="M17.83 10.17a.582.582 0 0 1 0 .823L14.823 14l3.007 3.007a.582.582 0 0 1-.823.823L14 14.823l-3.007 3.007a.582.582 0 1 1-.823-.823L13.177 14l-3.007-3.007a.582.582 0 1 1 .823-.823L14 13.177l3.007-3.007a.582.582 0 0 1 .823 0Z"
        fill="currentColor"
      />
      <circle stroke="currentColor" cx={14} cy={14} r={13.5} />
    </g>
  </svg>
);
