import {createRoot} from 'react-dom/client';
import App from "./App";
import Main from "./pages/Main";
import StoreProvider from "./StoreProvider";

window.DMTConfigurator = {

  init: (elementId, options) => {
    window.DMTConfigurator.elementId = elementId;
    window.DMTConfigurator.__root = createRoot(document.getElementById(elementId));
    window.DMTConfigurator.__root.render(
      <StoreProvider options={options}>
        <App>
          <Main />
        </App>
      </StoreProvider>
    );
    console.log("DMTConfigurator react component");
    console.log("Options", options);
  },

  unmount: () => {
    if(window.DMTConfigurator.__root) window.DMTConfigurator.__root.unmount();
  },
};
