.control {
    background: transparent;
    border-bottom: 1px solid #fff;
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
}


.container .react-select-container {
    border-color: transparent;
    box-shadow: none;
    background-color: transparent;
    border-bottom: 1px solid #fff;
    border-radius: 0;
    border-left: none;
    border-right: none;
    border-top: none;
    outline: none;
}

.container .react-select-container:hover {
    border-color: transparent;
    border-bottom: 1px solid #fff;
    box-shadow: none;
}

.option {
    background-color: transparent;
    color: white;
    height: 33px;
    margin: .4rem 0;
}

.option:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.selected {
    composes: option;
    background-color: rgba(255, 255, 255, 0.08);
}