import Stack from "../atoms/Stack";
import Inline from "../atoms/Inline";
import TextSelector from "../molecules/TextSelector";
import Select from "../molecules/Select";
import flagsGroup from "../configurations/flagsGroup";
import Label from "../atoms/Label";
import * as styles from "./DetailRow.module.css";
import TrashIcon from "../icons/TrashIcon";
import useAppStore from "../hooks/useAppStore";
import { useMemo } from "react";

export default function DetailRow({
  item = { text: '', flag: null, size: null},
  row,
  onDelete,
  onChange,
  ...props
}) {
  
  const {sizes, modelId} = useAppStore( state => ({
    sizes: state.sizes,
    modelId: state.modelId
  }));
  
  const sizeConfigurations = useMemo(function () {
    return sizes.map((size, idx) => ({
      id: `${idx}-${size}`,
      name: size,
    }));
  }, [sizes]);
  
  
  return (
    <Inline verticalAlign="center" style={{height: '4.75rem', gap: '2rem' }}>
      
      <Stack gap="none">
        <Label weight="weak" size="normal">&nbsp;</Label>
        <div className={styles.rowcount}>
          <Label size="medium" spacing="medium">{row}.</Label>
        </div>
      </Stack>
      
      <Stack gap="none">
        <Label weight="weak" size="normal">Name</Label>
        <TextSelector
          value={item.text}
          placeholder="Add custom name"
          style={{
            borderBottom: 0,
          }}
          onChange={ value => onChange(row, {text: value})}
        />
      </Stack>
      
      <Stack gap="none">
        <Label weight="weak" size="normal">Country</Label>
        <Select
          selected={{
            ...flagsGroup[modelId].configurations.find( flag => flag.name.toLowerCase() === item.flag),
            icon: item.flag,
          }}
          placeholder="Country"
          items={flagsGroup[modelId].configurations.map( flag => ({
            value: flag.name,
            label: flag.label,
            icon: flag.name.toLowerCase()
          }))}
          onChange={ detail => onChange(row, {flag: detail.value})}
        />
      </Stack>
      
      <Stack gap="none">
        <Label weight="weak" size="normal">Size</Label>
          <Select
            selected={{
              value: item.size,
              label: item.size,
            }}
            placeholder="Size"
            items={sizeConfigurations.map( size => ({
              value: size.name,
              label: size.name
            }))}
            size="small"
            onChange={ detail => onChange(row, {size: detail.value})}
          />
      </Stack>
      <span>
        <button
          onClick={() => onDelete(row) }
        >
          <TrashIcon />
        </button>
      </span>
    </Inline>
  )
  
  
}