import {createStore} from "zustand";
import {createDmtConfiguratorSlice} from "./createDmtConfiguratorSlice";
import {createApplicationSlice} from "./createApplicationSlice";
import models from "../configurations/models";
import defaultSizes from "../configurations/defaultSizes";
import sizeGroup from "../configurations/sizeGroup";
import flagsGroup from "../configurations/flagsGroup";
import { devtools } from "zustand/middleware";
import nameGroup from "../configurations/nameGroup";
import quantityGroup from "../configurations/quantityGroup";
import modes from "../configurations/modes";

export default function storeCreator({
  modelId= 'masterSkuKr0',
  sizes= defaultSizes,
  materials= [],
  lang= 'en',
  mode= modes.B2C,
  b2c= {
    enableFlags: false,
    enableText: false,
  },
  details= [],
  textColor = null,
  flagOutlineColor = null,
  ...props
}) {
  
  
  const configurableGroups = [...models[modelId].configurableGroups];

  if(mode === modes.B2C) {
    configurableGroups.push({
      ...sizeGroup,
      configurations: sizes.map((size, idx) => ({
          id: `${idx}-${size}`,
          name: size,
        })
      )
    });
  }
  
  if(mode === modes.B2B || b2c.enableFlags) {
    configurableGroups.push(flagsGroup);
  }
  
  if(mode === modes.B2B || b2c.enableText) {
    configurableGroups.push(nameGroup);
  }
  
  if(mode === modes.B2B) {
    configurableGroups.push(quantityGroup);
  }

  //Init 3d models with this materials configuration
  const currentMaterials = materials.reduce( (acc, current, idx, arr) => {
    const group = models[modelId].configurableGroups.find( group => group.name === current.groupName);
    let result = [...acc];
    if(group !== undefined) {
      const configuration = group.configurations.find( configuration => configuration.name === current.configuration.name);
      if(configuration !== undefined) {
        result = [...result, {
          group,
          configuration,
        }];
      }
    }
    return result;
  }, []);

  return createStore(devtools( (state)  => ({
    ...createDmtConfiguratorSlice(state),
    ...createApplicationSlice(state),
    sizes,
    modelId,
    modelConfiguration: {
      ...models[modelId],
      configurableGroups,
    },
    currentMaterials,
    mode,
    b2c,
    textColor,
    flagOutlineColor,
    details,
    lang,
    ...props,
  })));

}
