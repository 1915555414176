export default {
  id: 'bandiera',
  name: "BANDIERA",
  label: "Bandiera",
  selectorType: "flag",
  camera: {
    "position": [0.20802095532417297,0.10165330767631531,0.2336360365152359],
    "target": [-0.07206711173057556,0.03663841634988785,-0.033031120896339417],
    "up": [0, 1, 0],
    "fov": 30,
    "transitionTime": 1000
  },
  configurations: [
    {
      name: "BHR",
      label: "BHR",
      code: "ita01",
      description: "Bahrain",
      materialName: "BAHRAIN"
    },
    {
      name: "GBS",
      label: "GBS",
      code: "ita01",
      description: "Scozia",
      materialName: "SCOZIA"
    },
    {
      name: "SAU",
      label: "SAU",
      code: "sau01",
      description: "Arabia Saudita, Saudi Arabia",
      materialName: "ARABIA-SAUDITA"
    },
    {
      name: "ARG",
      label: "ARG",
      code: "ita01",
      description: "Argentina, Argentina",
      materialName: "ARGENTINA"
    },
    {
      name: "AUS",
      label: "AUS",
      code: "ita01",
      description: "Australia, Australia",
      materialName: "AUSTRALIA"
    },
    {
      name: "AUT",
      label: "AUT",
      code: "ita01",
      description: "Austria, Austria",
      materialName: "AUSTRIA"
    },
    {
      name: "TUR",
      label: "TUR",
      code: "ita01",
      description: "Turchia",
      materialName: "TURCHIA"
    },
    {
      name: "TWN",
      label: "TWN",
      code: "ita01",
      description: "Taiwan",
      materialName: "TAIWAN"
    },
    {
      name: "CHE",
      label: "CHE",
      code: "ita01",
      description: "Svizzera",
      materialName: "SVIZZERA"
    },
    {
      name: "SWE",
      label: "SWE",
      code: "spa01",
      description: "Svezia",
      materialName: "SVEZIA"
    },
    {
      name: "BEL",
      label: "BEL",
      code: "spa01",
      description: "Belgio, Belgium",
      materialName: "BELGIO"
    },
    {
      name: "BRA",
      label: "BRA",
      code: "spa01",
      description: "Brasile, Brazil",
      materialName: "BRASILE"
    },
    {
      name: "HRV",
      label: "HRV",
      code: "spa01",
      description: "Croazia, Croatia",
      materialName: "CROAZIOA"
    },
    {
      name: "CAN",
      label: "CAN",
      code: "spa01",
      description: "Canada, Canada",
      materialName: "CANADA"
    },
    {
      name: "KOR",
      label: "KOR",
      code: "spa01",
      description: "Corea del Sud, South Korea",
      materialName: "COREA-DEL-SUD"
    },
    {
      name: "DNK",
      label: "DNK",
      code: "spa01",
      description: "Danimarca, Denmark",
      materialName: "DANIMARCA"
    },
    {
      name: "ARE",
      label: "ARE",
      code: "spa01",
      description: "Emirati Arabi Uniti, United Arab Emirates",
      materialName: "EMIRATI-ARABI"
    },
    {
      name: "FRA",
      label: "FRA",
      code: "spa01",
      description: "Francia, France",
      materialName: "FRANCIA"
    },
    {
      name: "FIN",
      label: "FIN",
      code: "spa01",
      description: "Finlandia, Finland",
      materialName: "FINLANDIA"
    },
    {
      name: "JPN",
      label: "JPN",
      code: "spa01",
      description: "Giappone, Japan",
      materialName: "GIAPPONE"
    },
    {
      name: "DEU",
      label: "DEU",
      code: "spa01",
      description: "Germania, Germany",
      materialName: "GERMANIA"
    },
    {
      name: "HKG",
      label: "HKG",
      code: "spa01",
      description: "Hong Kong, Hong Kong",
      materialName: "HONG-KONG"
    },
    {
      name: "ITA",
      label: "ITA",
      code: "spa01",
      description: "Italia, Italy",
      materialName: "ITALIA"
    },
    {
      name: "ISL",
      label: "ISL",
      code: "spa01",
      description: "Islanda, Iceland",
      materialName: "ISLANDA"
    },
    {
      name: "IRL",
      label: "IRL",
      code: "spa01",
      description: "Irlanda, Ireland",
      materialName: "IRLANDA"
    },
    {
      name: "LUX",
      label: "LUX",
      code: "spa01",
      description: "Lussemburgo, Luxembourg",
      materialName: "LUSSEMBURGO"
    },
    {
      name: "MLT",
      label: "MLT",
      code: "spa01",
      description: "Malta, Malta",
      materialName: "MALTA"
    },
    {
      name: "NOR",
      label: "NOR",
      code: "spa01",
      description: "Norvegia, Norway",
      materialName: "NORVEGIA"
    },
    {
      name: "NZL",
      label: "NZL",
      code: "spa01",
      description: "Nuova Zelanda, New Zealand",
      materialName: "NUOVA-ZELANDA"
    },
    {
      name: "NLD",
      label: "NLD",
      code: "spa01",
      description: "Paesi Bassi, Netherlands",
      materialName: "OLANDA"
    },
    {
      name: "OMN",
      label: "OMN",
      code: "spa01",
      description: "Oman, Oman",
      materialName: "OMAN"
    },
    {
      name: "PRT",
      label: "PRT",
      code: "spa01",
      description: "Portogallo, Portugal",
      materialName: "PORTOGALLO"
    },
    {
      name: "POL",
      label: "POL",
      code: "spa01",
      description: "Polonia, Poland",
      materialName: "POLONIA"
    },
    {
      name: "ENG",
      label: "ENG",
      code: "spa01",
      description: "Regno Unito, England",
      materialName: "REGNO-UNITO"
    },
    {
      name: "SPA",
      label: "SPA",
      code: "spa01",
      description: "Spagna, Spain",
      materialName: "SPAGNA"
    },
    {
      name: "ZAF",
      label: "ZAF",
      code: "spa01",
      description: "Sud Africa, South Africa",
      materialName: "SUD-AFRICA"
    },
    {
      name: "SVN",
      label: "SVN",
      code: "spa01",
      description: "Slovenia, Slovenia",
      materialName: "SLOVENIA"
    },
    {
      name: "USA",
      label: "USA",
      code: "spa01",
      description: "Stati Uniti d'America, United States",
      materialName: "STATI-UNITI"
    },
  ]
}