import { useEffect, useState, useMemo } from 'react';

const SCRIPT_STATUS_IDLE = 0;
const SCRIPT_STATUS_LOADING = 1;
const SCRIPT_STATUS_READY = 2;
const SCRIPT_STATUS_ERROR = 3;

export default function useScript(src, attrs= {}) {
  const sources = useMemo(() => (
    Array.isArray(src) ? src : [src]
  ), [src]);

  const [statuses, setStatuses] = useState(sources.reduce((statuses, source) => {
    statuses[source] = SCRIPT_STATUS_IDLE;
    return statuses;
  }, {}));

  useEffect(function() {
    function onload(event) {
      const src = event.target.src;

      setStatuses(statuses => ({
        ...statuses,
        [src]: SCRIPT_STATUS_READY,
      }));
    }

    function onerror(event) {
      const src = event.target.src;

      setStatuses(statuses => ({
        ...statuses,
        [src]: SCRIPT_STATUS_ERROR,
      }));
    }

    const scripts = sources.map(function(source) {
      const script = document.createElement('script');
      script.src = source;
      script.async = true;
      script.onload = onload;
      script.onerror = onerror;

      Object.keys(attrs).forEach( attribute =>
        script.setAttribute(attribute, attrs[attribute])
      );

      document.head.appendChild(script);

      setStatuses(statuses => ({
        ...statuses,
        [script.src]: SCRIPT_STATUS_LOADING,
      }));

      return script;
    });

    return function() {
      scripts.forEach(script => script.remove());
    }
  }, [sources]);

  return Object.values(statuses).every(status => (
    status === SCRIPT_STATUS_READY
  ));
}
