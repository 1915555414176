import defaultSizes from "./defaultSizes";

export default {
  id: 'taglia',
  name: "TAGLIA",
  label: "Taglia",
  selectorType: "size",
  configurations: defaultSizes.map( (size, idx) => ({
    id: `${idx}-${size}`,
    name: size,
  }))
}
