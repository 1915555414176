import * as styles from "../templates/MainTemplate.module.css";
import Inline from "../atoms/Inline";
import Label from "../atoms/Label";
import useAppStore from "../hooks/useAppStore";
import InfoIcon from "../icons/InfoIcon";
import { useTranslation } from "react-i18next";
import modes from "../configurations/modes";

export default function ProductInfo({
  name,
  price,
  ...props
}) {
  
  const { t, i18n } = useTranslation();
  
  const {showRecap, mode, showARModal, currentGroup} = useAppStore( (state) => ({
    showRecap: state.showRecap,
    showARModal: state.showARModal,
    currentGroup: state.currentGroup,
    mode: state.mode,
  }));

  const productInfoClassName = showARModal ? `${styles.productInfo} ${styles.hide}` : `${styles.productInfo}`;
  const personalizationWarningClassName = showARModal ? `${styles.personalizationWarning} ${styles.hide}` : `${styles.personalizationWarning}`;
  
  return (
    <>
      {showRecap === false && (
        <div className={productInfoClassName}>
          <Inline>
            <Label size="large" weight="medium">{name}</Label>
            { mode === modes.B2C && (<Label size="large" weight="medium" color='#ff7500'>{price}</Label>)}
          </Inline>
        </div>
    )}
      {currentGroup && (currentGroup.name === 'NOME' || currentGroup.name === 'BANDIERA') && (
        <div className={personalizationWarningClassName}>
          <Inline>
            <InfoIcon />
            <Label color="#ff7500">{t('text.personalizationWarning')}</Label>
          </Inline>
        </div>
      )}
    </>
  );
}
