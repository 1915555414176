.button {
    composes: button from '../style.module.css';
    width: 2.75rem;
    height: 2.75rem;
    padding: 0;
    border-radius: 50%;
    border: 1px solid var(--input-text-bottom-border-color);
    background-color: transparent;
    color: var(--button-secondary-text-color);
    font-size: .875rem;
    font-weight: lighter;
}

.button:hover {
    border: 1px solid #fff;
}

.quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 7.5rem;
    height: 2.75rem;
    padding: .75rem;
    border-radius: 12.5rem;
    border: 1px solid var(--button-secondary-text-color);
    padding: .5rem .75rem;
    color: var(--button-secondary-text-color);
    font-weight: lighter;
}