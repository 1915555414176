.menu {
  composes: w100 from "../style.module.css";
  position: relative;
  height: 100%;
  padding: 0 .6rem;
}

.menu > div:first-child {
  margin-bottom: 9.71rem;
}
.menu span {
  text-transform: uppercase;
}

.menuFooter {
  composes: w100 from '../style.module.css';
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 9.71rem;
  background-color: var(--menu-footer-background-color);
  color: var(--menu-footer-foreground-color);
  transition-property: all;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
  opacity: 0;
}
.menuFooter button {
  width: 100%;
  text-transform: uppercase;
}

.menuFooterOpened {
  opacity: .8;
}

.scrollable {
  overflow: auto;
}

.pipe {
  width: 1px;
  height: 2.85rem;
  border-right: 1px solid rgba(255 255 255 / 0.21);
}

.buttonContainer button {
  color: var(--main-container-foreground-color);
}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .menu {
    padding-right: 3.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .menu > div:first-child {
    margin-bottom: 0;
  }

  .menuFooterOpened {
    display: none;
  }
}
