import React from 'react';
import * as styles from './Button.module.css';

export default function Button({
 primary = true,
 purchase= false,
 disabled,
 color,
 backgroundColor,
 transparent = false,
 onClick,
 children,
 ...props
}) {

  const className = primary === true
    ? styles.primary
    : purchase === true
      ? styles.purchase
      : styles.secondary;

  const background = transparent ? 'transparent' : backgroundColor;
  const border = transparent ? 0 : 'auto';

  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={className}
      style={{backgroundColor: background, color, border}}
      {...props}
    >
      <span>{children}</span>
    </button>
  );
}
