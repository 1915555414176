import Header from "../organisms/Header";
import * as styles from "./MainTemplate.module.css";
import useAppStore from "../hooks/useAppStore";
import Modal from "../organisms/Modal";
import MenuContainer from "../organisms/MenuContainer";
import Footer from "../organisms/Footer";
import ProductInfo from "../organisms/ProductInfo";
import {useTranslation} from "react-i18next";
import TeamCustomization from "../organisms/TeamCustomization";

export default function MainTemplate({
  children,
  ...props
}) {

  const { t } = useTranslation();

  const {
    menuOpened, resetModalOpened, toggleResetModal, price, showARModal,
    toggleShowARModal, toggleShowTeamCustomization, showTeamCustomization
  } = useAppStore( state => ({
    menuOpened: state.menuOpened,
    resetModalOpened: state.resetModalOpened,
    toggleResetModal: state.toggleResetModal,
    price: state.price,
    showARModal: state.showARModal,
    toggleShowARModal: state.toggleShowARModal,
    showTeamCustomization: state.showTeamCustomization,
    toggleShowTeamCustomization: state.toggleShowTeamCustomization,
  }));

  const {
    modelConfiguration, setCurrentMaterials, setCurrentGroup,
    setDetails, details, setTextColor, setFlagOutlineColor } = useAppStore( (state) => ({
    modelConfiguration: state.modelConfiguration,
    setCurrentMaterials: state.setCurrentMaterials,
    setCurrentGroup: state.setCurrentGroup,
    setDetails: state.setDetails,
    details: state.details,
    setTextColor: state.setTextColor,
    setFlagOutlineColor: state.setFlagOutlineColor
  }));

  let menuContainerClassName = `${styles.menuContainer} ${menuOpened ? styles.menuContainerOpened : null}`;
  menuContainerClassName = showARModal ? `${menuContainerClassName} ${styles.hide}` : `${menuContainerClassName}`;

  const headerClassName = showARModal ? `${styles.header} ${styles.hide}` : `${styles.header}`;
  const footerClassName = showARModal ? `${styles.footer} ${styles.hide}` : `${styles.footer}`;

  const confirmReset = () => {
    setCurrentMaterials([]);
    setDetails([]);
    setTextColor(null);
    setFlagOutlineColor(null);
    setCurrentGroup(modelConfiguration.configurableGroups[0]);
    toggleResetModal();
  };

  return (
    <div className={styles.main}>

      <div className={headerClassName}>
        <Header />
      </div>

      <div className={styles.contents}>
        {children}

        <ProductInfo name={modelConfiguration.name} price={price} />

        <div className={menuContainerClassName}>
          <MenuContainer />
        </div>

      </div>

      <div className={footerClassName}>
        <Footer />
      </div>

      <Modal
        isOpen={resetModalOpened}
        message={t("text.reset")}
        title={t("text.notice")}
        onCancel={toggleResetModal}
        onConfirm={confirmReset}
        onClose={toggleResetModal}
      >
      </Modal>
      
      <Modal
        isOpen={showTeamCustomization}
        title={"CUSTOMIZE YOUR TEAM"}
        onClose={toggleShowTeamCustomization}
        size="medium"
      >
        <TeamCustomization items={details} />
      </Modal>

    </div>
  );
}
